import React, { Component } from "reactn";
import axios from "axios";
import moment from "moment";
import { formTypes } from "../../components/UI/form";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DateSel from "react-datepicker";
import {
  StripeProvider,
  CardElement,
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "react-stripe-elements";
import { BUTTON } from "./../../components/modals/baseModal/FieldTypes"
import { WHITE } from "./../../components/modals/baseModal/colours";
// import {
//   StripeProvider,
//   CardElement,
//   Elements,
//   injectStripe,
//   CardNumberElement,
//   CardExpiryElement,
//   CardCvcElement
// } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import SignModal from "../../components/modals/SignModal";
import EditParentModal from "../../components/modals/EditParentInfoModal";
import EditChildModal from "../../components/modals/EditChildInfoModal";
import AddChildModal from "../../components/modals/AddChildModal";
import ParentResetPasswordModal from "../../components/modals/ParentResetPasswordModal";
import AddPromoCodeModal from "../../components/modals/AddPromoCodeModal";
import { format, addDays, isSameDay } from "date-fns";
import "../../assets/css/componentSpecificCss/checkout.css";
import ProgramSessionsTiming from "./CheckoutTiming/programSessionsTiming";
import ProgramSessionClassDates from "./CheckoutTiming/programSessionClassDates";
import { withRouter, Link } from "react-router-dom";

import {
  getSelectedProgramPlan,
  PROP_PROGRAM,
  PROP_EVENT,
  PROP_MEMBERSHIP,
  PROP_PARTY,
  PROP_ONLINE,
  applyGift
} from "./Checkout/utils";

import CheckoutEvent from "./Checkout/CheckoutEvent";
import CheckoutParty from "./Checkout/CheckoutParty";
import CheckoutMembership from "./Checkout/CheckoutMembership";
import CheckoutProgram from "./Checkout/CheckoutProgram";
import CheckoutOnline from "./Checkout/CheckoutOnline";
import CheckoutSubmission from "./Checkout/CheckoutSubmission";
import DatePickerField from "../../components/modals/baseModal/fieldComponents/DatePicker/DatePicker";
import OfflinePaymentCheckoutSubmission from "./Checkout/OfflinePaymentCheckoutSubmission";

const DOLLAR_TO_CENTS = 100;

const RoundToTwo = (str, val) => {
  str = str.toString();
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
};

const styles = {
  input: {
    height: "3em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Open sans, sans-serif !important",
    paddingLeft: "0.5em",
    fontWeight: "500"
  },
  textArea: {
    fontFamily: "Open sans, sans-serif !important",
    fontWeight: "500 !important",
    padding: "10px 5px 10px 5px !important"
  }
};

class _CardForm extends React.Component {
  state = {
    errorMessage: "",
    cardName: "",
    stripe: null,
    requiredFields: []
  };
  async componentDidMount() {
    if (document.getElementsByClassName("StripeElement"))
      document.getElementsByClassName("StripeElement")[0].style.padding =
        "10px";

    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      let con = null;
      if (res.data.success) {
        con = res.data.data;
      }
      this.setState({ stripe: con });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  handleSubmit = async evt => {
    this.setGlobal({
      loading: true
    })
    evt.preventDefault();
    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    if (this.props.stripe && !this.state.stripe) {
      try {
        const token = await this.props.stripe.createToken({
          name: this.state.cardName
        });

        if (this.state.cardName.trim().length < 1) {
          toast.error("The cardholder name cannot be empty.");
          return;
        }
        if (token.error)
          throw new Error("There was an error processing your card!");
        const res = await axios.post(ep, {
          source: token
        });
        if (res.data.success) {
          this.setState({
            stripe: res.data.data
          });
          this.props.update(res.data.data);
          toast("Successfully saved payment info!");
        } else {
          toast(res.data.message);
        }
        this.setGlobal({ loading: false, lastAPICall: res });
        return;
      } catch (ex) {
        toast.error(ex.message);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
    if (this.props.stripe && this.state.stripe) {
      console.log("STRIPE INFO?", this.props.stripe, this.state.stripe)
      // Partner listings checkout endpoint.
      try {
        const setupIntent = await axios.post(`${process.env.REACT_APP_API}/partners/stripe/setup-intent`, {});

        if (!setupIntent.data.success) {
          toast.error("An error ocurred with your card")
          this.setGlobal({ loading: false })
          return;
        }

        if (setupIntent.data.data.status === "requires_confirmation") {
          const result = await this.props.stripe.confirmCardSetup(
            setupIntent.data.data.client_secret
          );

          if (result.error) {
            toast.error("An error occurred while authenticating your payment.");
            this.setGlobal({ loading: false })
            return;
          } else {
            const paymentMethod = await axios.post(`${process.env.REACT_APP_API}/partners/stripe/set-default-payment-method`, {
              paymentMethod: result.setupIntent.payment_method
            });

            if (!paymentMethod.data.success) {
              toast.error("An error occurred while trying to save Payment Method");
              this.setGlobal({ loading: false })
              return;
            }
          }
        }

        const ep = `${process.env.REACT_APP_API}/partners/stripe/register`;
        this.setGlobal({ loading: true });
        const data = {
          stripeId: this.state.stripe.id,
          plan: this.props.plan,
          partner: this.props.partner,
          type: this.props.type,
          installments: this.props.installments,
          program: this.props.program,
          promotion: this.props.promotion,
          dropins: this.props.dropins,
          selectedDays: this.props.selectedDays,
          child: this.props.child,
          formData: this.props.formData,
          startDate: this.props.startDate,
          stripeStartDate: this.props.stripeStartDate, // specific to the recurring programs for stripe subscription start
          formId: this.props.formId,
          recurringCustomerSelectedDays:
            this.props.recurringCustomerSelectedDays,
          promoCode:
            this.global.promoCode === "" ? null : this.global.promoCode,
          applyGiftCard: !!this.global.giftBalance,
          itemAmount: this.props.itemAmount,
          depositAmount: this.props.depositAmount,
          taxAmount: this.props.taxAmount,
          promotionAmount: this.props.promotionAmount,
          couponAmount: this.props.couponAmount,
          creditCardFeeAmount: this.props.creditCardFeeAmount,
          totalAmount: this.props.totalAmount,
          addOnSemesterItemAmount: this.props.addOnSemesterItemAmount,
          isWaiveDeposit: this.props.isWaiveDeposit
        };

        const res = await axios.post(ep, data);

        this.setGlobal({ loading: false });
        if (res.data.success) {
          toast.success(`Payment Successful`);
          this.props.success();
          if (this.global.coupon) {
            const couponEp = `${process.env.REACT_APP_API}/partners/stripe/coupon/update/redeem-count/${this.global.coupon.id}`;
            const updateRedemptionCount = await axios.put(couponEp);
          } else if (this.props.promoCode) {
            const couponEp = `${process.env.REACT_APP_API}/partners/stripe/coupon/update/redeem-count/${this.props.promoCode.id}`;
            const updateRedemptionCount = await axios.put(couponEp);
          }

          this.setGlobal({ promoCode: "", coupon: null });
        } else {
          this.setGlobal({ promoCode: "", coupon: null });
          toast.error(`Payment Failed`);
        }
      } catch (err) {
        console.log("ERROR", err);
      }
    }
    this.setGlobal({
      loading: false
    })
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return (
      <>
        {!this.state.stripe && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px"
              }}
            >
              <label
                style={{
                  fontWeight: "normal",
                  width: "30%",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "16px"
                }}
              >
                Cardholder name:<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                style={{
                  flexGrow: "1",
                  border: "1px solid #E8E8E8",
                  padding: "5px"
                }}
                value={this.state.cardName}
                onChange={e => this.setState({ cardName: e.target.value })}
              />
            </div>
            <div
              style={{
                background: "white",
                padding: "10px",
                border: "1px solid gainsboro"
              }}
            >
              <CardElement
                onChange={this.handleChange}
                onReady={el => this.setState({ cardElement: el })}
                style={{ padding: "15px", backgroundColor: "white" }}
              />

              {/* <CardNumberElement onChange={this.handleChange} />
              <CardExpiryElement onChange={this.handleChange} />
              <CardCvcElement onChange={this.handleChange} /> */}
            </div>
            <p style={{ color: "red" }}>{this.state.errorMessage}</p>
          </>
        )}
        {this.state.stripe && (
          <>
            <div
              style={{
                backgroundColor: "#fff",
                width: "90%",
                margin: "0 auto 10px"
              }}
            >
              <div>
                <div style={{ display: "flex", padding: "20px" }}>
                  <p style={{ fontWeight: "bold", width: "20%" }}>
                    {this.state.stripe.sources.data[0].brand}
                  </p>
                  <p style={{ flexGrow: 1 }}>
                    ****_****_****_{this.state.stripe.sources.data[0].last4}
                  </p>
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={_ => this.setState({ stripe: null })}
                  >
                    Edit
                  </span>
                </div>
                <div style={{ display: "flex", padding: "20px" }}>
                  <p
                    style={{ fontWeight: "bold", width: "30%", color: "gray" }}
                  >
                    Expiry Date
                  </p>
                </div>
                <div style={{ display: "flex", padding: "20px" }}>
                  <p style={{ width: "20%" }}>
                    {this.state.stripe.sources.data[0].exp_month}/
                    {this.state.stripe.sources.data[0].exp_year}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {!this.state.stripe && (
          <button
            type="button"
            onClick={e => this.handleSubmit(e)}
            style={{
              padding: "10px",
              display: "block",
              marginLeft: "auto",
              marginBottom: 0,
              background: "#ff6663",
              color: "white",
              border: "none",
              borderRadius: "3px"
            }}
          >
            Validate Card
          </button>
        )}
        <button
          disabled={!this.state.stripe}
          type="button"
          onClick={e => {
            if (this.state.stripe) {
              this.handleSubmit(e);
            }
          }}
          style={{
            padding: "10px",
            display: "block",
            marginLeft: "auto",
            marginBottom: 0,
            color: "white",
            border: "none",
            borderRadius: "3px",
            position: "absolute",
            top: "110%",
            left: "38%",
            cursor: this.state.stripe ? "pointer" : "not-allowed",
            background: this.state.stripe ? "#1eab14" : "#41653e"
          }}
        >
          Complete Order
        </button>
      </>
    );
  }
}
const CardForm = injectStripe(_CardForm);
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleSessionEnrollments: [],
      sections: [],
      days: {},
      lastPage: false,
      dropInPage: false,
      selected_days: [],
      guardians: [],
      formData: {},
      children: [],
      doesParentHaveChild: false,
      isRecurringProgramDateSelect: false,
      startDate: props.program.program_start
        ? new Date(props.program.program_start).toISOString()
        : null,
      stripeStartDate: props.program.program_start
        ? new Date(props.program.program_start).toISOString()
        : null,
      displayDate: props.startDate ? new Date(props.startDate) : null,
      programAdditionalPage: false,
      formValidationFields: [],
      selectedDropdownValue: "",
      checkFormContentValidation: false,
      openPromoCodeModal: false,
      recurringCustomerSelectedDays: props.recurringCustomerSelectedDays
        ? props.recurringCustomerSelectedDays
        : [],
      toRemoveDays: [],
      isEnableWaitlist: this.props.planWaitlist
        ? this.props.planWaitlist
        : false,
      isGuardianNull: false,
      itemAmount: 0,
      depositAmount: 0,
      taxAmount: 0,
      party_details: false,
      partyDetails: {},
      promotionAmount: 0,
      couponAmount: 0,
      creditCardFeeAmount: 0,
      totalAmount: 0,
      addOnSemesterItemAmount: 0,
      giftBalance: 0,
      isProgramSessionsDateSelect: false,
      isProgramSessionsTimeSelect: false,
      singleQuantityTimeUnselectedValidation: false,
      multipleQuantityTimeUnselectedValidation: false,
      sessionDateTimes: null,
      classSessionDailySelectedDays: [],
      classSessionDailyUnselectedDatesValidation: false,
      classSessionWeeklyUnselectedDatesValidation: false,
      classSessionWeeklyDate: null,
      sessionWeeklySelectedDays: [],
      redHighlight: false,
      isWaiveDeposit: props.isWaiveDeposit ? props.isWaiveDeposit : false,
      isStartSet: props.isStartSet ? props.isStartSet : false,
      duplicatePhone: false,
      openResetPasswordModal: false,
      newChild: {
        allergies: [],
        birthDate: "",
        comments: "",
        dislikes: [],
        fullName: "",
        gender: "",
        healthConcerns: [],
        immunized: "",
        languages: [],
        likes: [],
        medication: [],
        pottyTrained: "",
        specialFood: []
      },
      isSubmitted: false
    };

    this.required = [
      { name: "Full Name", validation: e => !e },
      { name: "Email", validation: e => !e },
      { name: "Cell Phone", validation: e => !e },
      { name: "Address", validation: e => !e },
      { name: "City", validation: e => !e },
      { name: "Province", validation: e => !e },
      { name: "Postal Code", validation: e => !e },
      { name: "Country", validation: e => !e }
    ];
    this.mappings = {
      fullName: "Full Name",
      email: "Email",
      homePhone: "Cell Phone",
      address: "Address",
      city: "City",
      province: "Region",
      country: "Country",
      postalCode: "Postal / Zip"
      // workPhone: "Work Phone"
    };

    // Reset the usage of any gift card on initial render.
    this.setGlobal({ giftBalance: 0 });
    this.elements = (
      element,
      label,
      type = undefined,
      width,
      secId,
      required,
      options = [],
      forceparagraph,
      policyOptions,
      sec,
      field,
      rowNum = 0
    ) => {
      let disableInput = this.props.program.addChild && sec.form_section_name === "Child Profile" && (field.first_label === "Full Name" || field.first_label === "Date of Birth");
      let value;
      if (this.props.program.addChild && sec.form_section_name === "Child Profile" && (field.first_label === "Full Name" || field.first_label === "Date of Birth")) {
        if (this.state.selectedChild) {
          value = this.state.children.find(e => parseInt(e.id) === parseInt(this.state.selectedChild))[field.first_label]

          if (field.first_label === "Date of Birth") {
            if (value) {
              value = new Date(value).toISOString().substring(0, 10);
              console.log("DATE OF BIRTH", value)
            } else {
              // child Date of Birth is invalid
              value = ""
              disableInput = false
            }
          }

          if (disableInput && this.state.formData && this.state.formData[secId + label] !== value) {
            this.setState({
              formData: {
                ...this.state.formData, [secId + label]: value
              }
            })
          }
        }
      }

      if (element === "input" && type === "file") {
        return (
          <div className="file-upload-element">
            <input
              type="file"
              style={{
                width: width,
                marginBottom: "10px",
                color: "transparent"
              }}
              name={secId + label}
              onChange={async e => {
                const fd = new FormData();
                fd.set("image", e.target.files[0]);

                // Upload the image to the server
                const res = await axios.post(
                  `${process.env.REACT_APP_API}/partners/forms/upload/${this.global.dsUser.id}`,
                  fd
                );
                if (res.data.success) {
                  console.log(res.data);
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      [secId +
                        label]: `${process.env["REACT_APP_DS_FILES_S3"]}/${res.data.data}`
                    }
                  });
                }
              }}
              required={required}
            />
          </div>
        );
      }
      if (element === "input")
        return forceparagraph ? (
          <p>{this.state[secId + label]}</p>
        ) : (
          <div>
            <input
              disabled={disableInput}
              type={type}
              style={{ width: width }}
              className={`${this.props.classes.input} ${type !== "file" && "form-input"
                }`}
              id="form-checkout-input"
              name={secId + label}
              onChange={e => {
                if (e.target.value.length === 0) {
                  const formDataCopy = this.state.formData;
                  delete formDataCopy[e.target.name];
                  this.setState({
                    formData: formDataCopy
                  });
                } else {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      [e.target.name]: e.target.value
                    }
                  });
                }
              }}
              required={required}
              value={this.state.formData[secId + label]}
            />
          </div>
        );
      if (element === "textarea")
        return forceparagraph ? (
          <p>{this.state[secId + label]}</p>
        ) : (
          <textarea
            type={type}
            rows={4}
            name={secId + label}
            style={{
              width: width,
              border: "1px solid #E8E8E8"
            }}
            id="checkout-form-textarea"
            className={this.props.classes.textArea}
            onChange={e => {
              if (e.target.value.length === 0) {
                const formDataCopy = this.state.formData;
                delete formDataCopy[e.target.name];
                this.setState({
                  formData: formDataCopy
                });
              } else {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                  }
                });
              }
            }}
            required={required}
            value={this.state.formData[secId + label]}
          ></textarea>
        );
      if (element === "days") {
        return (
          <div
            name={secId + label}
            className="row days"
            validation={"validDays"}
            style={{ width: width, margin: 0 }}
          >
            <div
              data-day="Monday"
              className={`day-monday day ${this.state[secId + label] && this.state[secId + label]["Monday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              M
            </div>
            <div
              data-day="Tuesday"
              className={`day-tuesday day ${this.state[secId + label] &&
                this.state[secId + label]["Tuesday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Tu
            </div>
            <div
              data-day="Wednesday"
              className={`day-wednesday day ${this.state[secId + label] &&
                this.state[secId + label]["Wednesday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              W
            </div>
            <div
              data-day="Thursday"
              className={`day-thursday day ${this.state[secId + label] &&
                this.state[secId + label]["Thursday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Th
            </div>
            <div
              data-day="Friday"
              className={`day-friday day ${this.state[secId + label] && this.state[secId + label]["Friday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              F
            </div>
            <div
              data-day="Saturday"
              className={`day-saturday day ${this.state[secId + label] &&
                this.state[secId + label]["Saturday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Sa
            </div>
            <div
              data-day="Sunday"
              className={`day-sunday day ${this.state[secId + label] && this.state[secId + label]["Sunday"]
                ? "selecta"
                : ""
                }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Su
            </div>
          </div>
        );
      }
      if (element === "radios")
        return (
          <div
            style={{
              display: "flex",
              width: width,
              marginLeft: "10px"
            }}
            className="form-multiselect"
          >
            <div style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name={secId + label}
                value="Immediately"
                checked={
                  this.state.formData[secId + label] === "Immediately"
                    ? true
                    : false
                }
                className="checkout-radio-element"
                onChange={e =>
                  this.setState({
                    [e.target.name]: e.target.value,
                    formData: {
                      ...this.state.formData,
                      [e.target.name]: e.target.value
                    }
                  })
                }
              />{" "}
              Immediately
            </div>
            <div style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name={secId + label}
                value="1-3 Months"
                checked={
                  this.state.formData[secId + label] === "1-3 Months"
                    ? true
                    : false
                }
                onChange={e =>
                  this.setState({
                    [e.target.name]: e.target.value,
                    formData: {
                      ...this.state.formData,
                      [e.target.name]: e.target.value
                    }
                  })
                }
                className="checkout-radio-element"
              />{" "}
              1-3 Months
            </div>
            <div style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name={secId + label}
                value="3+ Months"
                checked={
                  this.state.formData[secId + label] === "3+ Months"
                    ? true
                    : false
                }
                onChange={e =>
                  this.setState({
                    [e.target.name]: e.target.value,
                    formData: {
                      ...this.state.formData,
                      [e.target.name]: e.target.value
                    }
                  })
                }
                className="checkout-radio-element"
              />{" "}
              3+ Months
            </div>
          </div>
        );
      if (element === "Dropdown")
        return (
          <div>
            <select
              style={{ width: "200px" }}
              className={"form-input"}
              name={secId + label}
              // value={this.state.formData[this.state.selectedDropdownValue]}
              onChange={e =>
                this.setState({
                  selectedDropdownValue: e.target.name,
                  formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                  }
                })
              }
            >
              <option value="" disabled selected>
                Please Select
              </option>
              {options.map(e => {
                if (this.state.formData[secId + label] === e.value) {
                  return (
                    <option value={e.value} selected>
                      {e.value}
                    </option>
                  );
                } else {
                  return <option value={e.value}>{e.value}</option>;
                }
              })}
            </select>
          </div>
        );
      if (element === "signature")
        return this.state.formData[secId + label] ? (
          <img src={this.state.formData[secId + label]} />
        ) : (
          <div style={{ marginTop: "10px", marginBottom: "24px" }}>
            <a
              href="#/"
              style={{
                border: "1px solid gainsboro",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                fontFamily: "Open sans, sans-serif"
              }}
              className="button-39"
              onClick={_ =>
                this.setState({ openSignModal: true, signVar: secId + label })
              }
            >
              Draw Signature
            </a>
          </div>
        );
      if (element === "Multiple Choices")
        return (
          <div
            style={{
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              border: "none",
              width: width
            }}
            className="form-multiselect"
          >
            {options.map(e => (
              <div style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  value={e.value}
                  name={secId + label}
                  style={{ marginRight: "5px" }}
                  checked={
                    this.state.formData[secId + label] === e.value
                      ? true
                      : false
                  }
                  onChange={e =>
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        [e.target.name]: e.target.value
                      }
                    })
                  }
                  className="checkout-radio-element"
                />
                {e.value}
              </div>
            ))}
          </div>
        );
      if (element === "Checkboxes")
        return (
          <div className="checkbox-group-element">
            {options.map((e, i) => {
              const secAndRow = (rowNum + 1) * Math.pow(10, Math.ceil(Math.log10(secId))) + secId;
              const boxId = secAndRow * Math.pow(10, Math.ceil(Math.log10(options.length))) + i;
              return (
                <div
                  className="checkbox-wrapper-1"
                  style={{
                    margin: "0px 0px 5px -10px",
                    fontWeight: "400",
                    fontFamily: "Open sans, sans-serif"
                  }}
                >
                  <input
                    key={i}
                    type="checkbox"
                    value={e.value}
                    name={secId + label}
                    id={boxId}
                    checked={
                      this.state.formData[secId + label]
                        ? this.state.formData[secId + label]
                          .split(", ")
                          .indexOf(e.value) !== -1
                        : false
                    }
                    onChange={e => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          [e.target.name]: this.state.formData[e.target.name]
                            ? e.target.checked
                              ? this.state.formData[e.target.name]
                                .split(", ")
                                .indexOf(e.target.value) !== -1
                                ? this.state.formData[e.target.name]
                                : this.state.formData[e.target.name] +
                                `, ${e.target.value}`
                              : this.state.formData[e.target.name]
                                .split(", ")
                                .filter(ex => ex !== e.target.value)
                                .join(", ")
                            : e.target.checked
                              ? e.target.value
                              : ""
                        }
                      });

                      if (!e.target.checked) {
                        const selectedArr = this.state.formData[secId + label]
                          ?.split(", ")
                          .filter(item => item !== e.target.value);
                        if (selectedArr.length === 0) {
                          const updatedFormData = { ...this.state.formData };
                          delete updatedFormData[e.target.name];
                          this.setState({
                            formData: updatedFormData
                          });
                        }
                      }
                    }}
                    className="substituted"
                  />
                  <label
                    htmlFor={boxId}
                    style={{ fontWeight: "400", margin: "0px 10px 5px 5px" }}
                  >
                    {e.value}
                  </label>
                </div>
              );
            })}
          </div>
        );
      if (element === "DOB") {
        return (
          <DateSel
            disabled={disableInput}
            className="w-100 dob-border"
            id="form-checkout-dob"
            showYearDropdown
            showMonthDropdown
            dateFormat="MMMM d, yyyy"
            name={label}
            onChange={e => {
              this.setState({
                formData: { ...this.state.formData, [secId + label]: e }
              })
            }

            }
            style={{}}
            selected={disableInput ? new Date(value) : this.state.formData[secId + label]}
          />
        );
      }

      if (element === "Policy") {
        const { isFileUpload, file, policyText, checkboxText, link } =
          policyOptions;
        const element = (
          <div style={{ fontFamily: "Open sans, sans-serif" }}>
            {policyText}
            <br />
            {(isFileUpload || link) && (
              <a
                href={
                  isFileUpload
                    ? `${process.env.REACT_APP_DS_FILES_S3}/${file}`
                    : link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                View Policy
              </a>
            )}
          </div>
        );
        return (
          <div className="policy-container">
            <p
              className="policy"
              style={{
                marginBottom: "0px",
                fontFamily: "'Open-sans', sans-serif"
              }}
            >
              {element}
            </p>
            <div
              style={{
                marginTop: "4px",
                display: "flex"
              }}
              className="checkbox-wrapper-1"
            >
              <input
                type="checkbox"
                name={secId + label}
                id={secId + label}
                style={{ marginRight: "10px" }}
                checked={this.state.formData[secId + label]}
                onClick={e => {
                  if (e.target.checked) {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        [e.target.name]: e.target.checked
                      }
                    });
                  } else {
                    const updatedFormData = { ...this.state.formData };
                    delete updatedFormData[e.target.name];
                    this.setState({
                      formData: updatedFormData
                    });
                  }
                }}
                className="substituted"
                required
              />
              <label
                htmlFor={secId + label}
                style={{
                  marginTop: "8px",
                  fontFamily: "'Open-sans', sans-serif"
                }}
              >
                {checkboxText}
              </label>
            </div>
          </div>
        );
      }
    };
  }

  saveProfile() {
    const Guardianarr = [];
    const Childarr = [];
    Object.keys(this.state).forEach(key => {
      if (key.includes("Parent / Guardian")) {
        const cleanString = key.replace("Parent / Guardian ", "");
        const index = parseInt(cleanString.substr(0, 1)) - 1;
        Guardianarr[index] = Guardianarr[index]
          ? { ...Guardianarr[index], [cleanString.substr(1)]: this.state[key] }
          : { [cleanString.substr(1)]: this.state[key] };
      }
      if (key.includes("Child - Profile")) {
        const cleanString = key.replace("Child - Profile ", "");
        const index = parseInt(cleanString.substr(0, 1)) - 1;
        Childarr[index] = Childarr[index]
          ? { ...Childarr[index], [cleanString.substr(1)]: this.state[key] }
          : { [cleanString.substr(1)]: this.state[key] };
      }
    });
    const toSaveGuard = Guardianarr.map(e => ({
      fullName: e["Full Name"], // + " " + e["Last Name"]
      email: e["Email"],
      address: e["Address"],
      homePhone: e["Phone"],
      postalCode: e["Postal Code"],
      province: e["Region"],
      country: e["Country"],
      id: e["id"]
    }));
    const toSaveKids = Childarr.map(e => ({
      fullName: e["Full Name"], // + " " + e["Last Name"]
      birthDate: e["Date of Birth"],
      id: e["id"]
    }));

    this.setGlobal({
      children: toSaveKids,
      parentGuardians: toSaveGuard
    });
    this.dispatch.saveProfile();
  }
  mapNewParent(parent) {
    return {
      "Full Name": parent.fullName,
      Email: parent.email,
      "Cell Phone": parent.homePhone,
      Address: parent.address,
      City: parent.city,
      Region: parent.province,
      Country: parent.country,
      "Postal / Zip": parent.postalCode,
      id: parent.id,
      isArchived: parent.isArchived
    };
  }
  mapNewChild(child) {
    return {
      isArchived: child.isArchived,
      "Full Name": child.fullName,
      "Date of Birth":
        new Date(child.birthDate).toString() === "Invalid Date"
          ? ""
          : format(new Date(child.birthDate), "LLLL dd, yyyy"),
      Allergies: child.allergies.map(e => e.name).join(", "),
      id: child.id
    };
  }
  async manualPayment() {
    let userQuantity = null;
    if (this.props.type !== "Event" && this.props.type !== "Membership") {
      userQuantity = this.props?.singleSessionQuantity || null;
    }
    const ep = `${process.env.REACT_APP_API}/partners/stripe/manual`;
    const plan = (() => {
      if (this.props.type === "Program" || this.props.type === "Online") {
        return this.props.program.plans.filter(
          e => JSON.parse(e).id === this.props.selected
        )[0];
      }
      if (this.props.type === "Event") {
        return this.props.program.tickets[this.props.selected];
      } else if (this.props.type === "Membership") {
        return this.props.program.prices[this.props.selected];
      } else if (this.props.type === "Party") {
        return this.props.program.tickets[this.props.selected];
      } else {
        throw new Error(
          `checkout.jsx - manualPayment() called on unrecognized type="${this.props.type}"`
        );
      }
    })();
    console.log("checkout.jsx 891: plan", plan);
    console.log("state submit:", this.state);
    console.log("props submit:", this.props);
    console.log("global submit:", this.global);

    //creates a copy of this.props.program without "enrollments" property.
    const { enrollments: _, ...this_props_program } = this.props.program;
    console.log("PLAN IN REQ DATA", plan);
    const data = {
      plan: plan,
      partner: this.props.program.partnerId,
      type: this.props.type,
      installments: this.props.installments,
      program: this_props_program,
      dropins: this.props.dropins,
      selectedDays: this.state.selected_days,
      child: this.state.selectedChild,
      application_required: this.props.program.application_required,
      isEnableWaitlist: this.state.isEnableWaitlist,
      formData: this.state.formData,
      startDate: this.state.startDate,
      formId: this.props.form,
      recurringCustomerSelectedDays: this.state.recurringCustomerSelectedDays,
      promoCode: this.global.promoCode === "" ? null : this.global.promoCode,
      applyGiftCard: !!this.global.giftBalance,
      itemAmount: this.state.itemAmount,
      depositAmount: this.state.depositAmount,
      taxAmount: this.state.taxAmount,
      promotionAmount: this.state.promotionAmount,
      couponAmount: this.state.couponAmount,
      creditCardFeeAmount: this.state.creditCardFeeAmount,
      totalAmount: this.state.totalAmount,
      addOnSemesterItemAmount: this.state.addOnSemesterItemAmount,
      userSelectedQuanity: userQuantity,
      sessionDateTimes: this.state.sessionDateTimes,
      classSessionDailySelectedDays: this.state.classSessionDailySelectedDays,
      classSessionDailyUnselectedDatesValidation:
        this.state.classSessionDailyUnselectedDatesValidation,
      classSessionWeeklyUnselectedDatesValidation:
        this.state.classSessionWeeklyUnselectedDatesValidation,
      classSessionWeeklyDate: this.state.classSessionWeeklyDate,
      sessionsCustomerSelectedDays: this.state.sessionWeeklySelectedDays,
      isWaiveDeposit: this.state.isWaiveDeposit
    };
    if (this.props.type === "Party") {
      data.partyDetails = this.state.partyDetails;
    }
    if (this.props.promotion) {
      data.promotion = this.props.promotion;
    }
    const res = await axios.post(ep, data);
    console.log("checkout.jsx 929: res", res);
    if (res.data.success) {
      if (this.props.program.manual_invoices && !this.state.isEnableWaitlist && !this.props.program.application_required) {
        this.setState({ 
          success: true, 
          orderReceiptId: res.data.data.depositOrder ? res.data.data.depositOrder.id : res.data.data.order.id,
        });
        return;
      }
      if (this.props.type === "Program" || this.props.type === "Online") {
        if (
          !this.props.program.auto_withdraw &&
          !this.props.program.application_required &&
          !this.state.isEnableWaitlist
        ) {
          const isDeposit = !!res.data.data?.depositOrder;
          const path = `/user/invoice/${res.data.data?.depositOrder
            ? res.data.data?.depositOrder.id
            : res.data.data?.order?.id
            }${isDeposit ? `/${isDeposit}` : `/${isDeposit}`}`;
          console.log("checkout.jsx 949: going to", path);
          this.props.history.push({
            pathname: path,
            state: { order: res.data.data.order }
          });
        }

        let invitationArchived = false;
        for (const inv of this.state.invitations) {
          if (
            inv.partnerId === this.props.program.partnerId &&
            inv.productId === this.props.program.id &&
            inv.planId === this.props.selected &&
            !inv.isArchived &&
            !invitationArchived
          ) {
            const ep = `${process.env.REACT_APP_API}/partners/invitations/archive/${inv.id}`;
            const results = await axios.post(ep);
            invitationArchived = true;
            break; // Exit the loop prematurely
          }
        }
      }
      if (this.props.type === "Membership") {
        if (
          !JSON.parse(this.props.program.prices[this.props.selected])
            .auto_withdraw &&
          !this.props.program.application_required &&
          !this.state.isEnableWaitlist
        ) {
          console.log("Manual membership");
          this.props.history.push({
            pathname: `/user/invoice/${res.data.data.order.id}/false`,
            state: { order: res.data.data?.order }
          });
        }

        let invitationArchived = false;
        for (const inv of this.state.invitations) {
          if (
            inv.partnerId === this.props.program.partnerId &&
            inv.productId === this.props.program.id &&
            inv.planId === this.props.selected &&
            !inv.isArchived &&
            !invitationArchived
          ) {
            const ep = `${process.env.REACT_APP_API}/partners/invitations/archive/${inv.id}`;
            const results = await axios.post(ep);
            invitationArchived = true;
            break; // Exit the loop prematurely
          }
        }
      }
      if (this.props.type === "Event") {
        if (
          !this.props.program.application_required &&
          !this.state.isEnableWaitlist
        ) {
          console.log("Manual event");
          this.props.history.push({
            pathname: `/user/invoice/${res.data.data.order.id}/false`,
            state: { prder: res.data.data?.order }
          });
        }

        let invitationArchived = false;
        for (const inv of this.state.invitations) {
          if (
            inv.partnerId === this.props.program.partnerId &&
            inv.productId === this.props.program.id &&
            inv.planId === this.props.selected &&
            !inv.isArchived &&
            !invitationArchived
          ) {
            const ep = `${process.env.REACT_APP_API}/partners/invitations/archive/${inv.id}`;
            const results = await axios.post(ep);
            invitationArchived = true;
            break; // Exit the loop prematurely
          }
        }
      }

      this.setState({ success: true });
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/search/forms/${this.props.form}`;
    const result = await axios.get(ep);
    await this.dispatch.getProfile();
    await this.fetchGiftData();

    if (this.global.dsUser) {
      const invitation_ep = `${process.env.REACT_APP_API}/partners/invitations/${this.global.dsUser.id}`;
      const invitations = await axios.get(invitation_ep);
      this.setState({ invitations: invitations.data.data });
    }

    const formSections = result.data.data;
    console.log("FORM SECTIONS", formSections);

    this.setState({ sections: formSections });

    const { parentGuardians } = this.global;
    console.log("PROPS in didmount: ", this.props);

    const children = this.global.children;

    if (parentGuardians && parentGuardians.length !== 0) {
      this.setState({ doesParentHaveChild: parentGuardians[0].has_child });
      // First guardian should have their Full Name, Email, and Cell Phone come from ds_user.
      parentGuardians[0].fullName = this.global.dsUser.displayName;
      parentGuardians[0].email = this.global.dsUser.email;
      parentGuardians[0].homePhone = this.global.dsUser.phone;
    } else {
      parentGuardians.push({
        fullName: this.global.dsUser.displayName,
        email: this.global.dsUser.email,
        homePhone: this.global.dsUser.homePhone
      });
    }

    const guardians = [];
    for (let i = 0; i < parentGuardians.length; i++) {
      guardians.push(this.mapNewParent(parentGuardians[i]));
    }
    let childrenArr = [];
    for (let i = 0; i < children.length; i++) {
      childrenArr.push(this.mapNewChild(children[i]));
    }

    if (this.props.type === "Program") {
      const element = JSON.parse(
        this.props.program.plans.filter(
          e => JSON.parse(e).id == this.props.selected
        )[0]
      );

      if (element.type === "Free Trial" && element.noChildrenAvailable === false) {
        childrenArr = [];
        for (let child of element.availableChildren) {
          childrenArr.push(this.mapNewChild(child));
        }
      }
      
      const isSingleSessionsTimeEnabled = !!element.isTimeEnabled;
      const isSingleSessionsDateSelectionEnabled =
        !!element.isDateSelectionEnabled;

      const isDateEnabled = !!(element.isStartDateEnabled || !element.type);

      const days_of_week = Object.keys(this.props.program.days_of_week);

      const excludedDays = this.getExcludedDays(days_of_week);

      this.fetchEnrollmentCount(this.props.program.id);

      const isCountLargerThanSpots = !!(
        this.props.program.total_spots &&
        (await this.fetchEnrollmentCount(this.props.program.id)) >=
        this.props.program.total_spots
      );

      let isEnableWaitlist = !!(
        this.props.program.enable_waitlist && isCountLargerThanSpots
      );
      //Secondary check only for semester and free programs at the moment
      if (this.props.planWaitlist === true) {
        isEnableWaitlist = this.props.planWaitlist;
      }

      if (isSingleSessionsDateSelectionEnabled || isSingleSessionsTimeEnabled) {
        this.fetchSingleSessionsEnrollments(this.props.program.id);
      }

      let alreadyEnrolled = false;
      if (this.props.program.allow_same_enrollment && this.props.program.program_category !== "Single Sessions" && !this.props.program.application_required && this.global.dsUser) {
        const allEnrollments = await this.fetchUserEnrollments();
        if (!this.props.program.addChild) {
          alreadyEnrolled = allEnrollments.filter(user =>
            user.programId === this.props.program.id &&
            user.status_stage !== "Cancelled" && user.status_stage !== "Inactive" &&
            user.status_stage !== "Cancelled_Unpaid" && user.status_stage !== "Rejected"
          ).length > 0;
        }
      }

      this.setState({
        isProgramSessionsDateSelect: isSingleSessionsDateSelectionEnabled,
        isProgramSessionsTimeSelect: isSingleSessionsTimeEnabled,
        isRecurringProgramDateSelect: isDateEnabled,
        toRemoveDays: excludedDays,
        isEnableWaitlist: isEnableWaitlist,
        alreadyEnrolled: alreadyEnrolled
      });
    }

    if (this.props.type === "Event") {
      const plan = JSON.parse((() => {
        return this.props.program.tickets[this.props.selected];
      })());

      let numEnrollmentInTicket = 0;

      const param = this.props.match.params.id;
      const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/${param}`;
      const customers = await axios.get(customers_ep);

      const purchases = customers.data.data;

      purchases.map(item => {
        if (
          item.status_stage !== "Inactive" &&
          item.plan_id === plan.id
        ) {
          numEnrollmentInTicket++;
        }
      });

      let isEnableWaitlist = !!(
        numEnrollmentInTicket >= plan.ticket_quantity
      );

      let notMember = false;
      let cartErrorProperties = {}
      const allEnrollments = await this.fetchUserEnrollments();
      if (plan.ticket_status === "Membership") {
        const isMember = allEnrollments.membership.filter(
          membership => membership.membershipId === parseInt(plan.selectedMembership)
        ).length > 0
        notMember = !isMember

        cartErrorProperties = {
          name: plan.ticket_name,
          notMember: true,
          membershipName: plan.published_memberships.filter(
            membership => membership.value === parseInt(plan.selectedMembership)
          )[0].text
        }
      }

      let alreadyEnrolled = false;
      if (this.props.program.allow_same_enrollment && !this.props.program.application_required && this.global.dsUser) {
        if (!this.props.program.addChild) {
          alreadyEnrolled = allEnrollments.event.filter(user =>
            user.eventId === this.props.program.id &&
            (user.status_stage !== "Inactive" && user.status_stage !== "Cancelled_Unpaid"
              && user.status_stage !== "Rejected" && user.status_stage !== "Cancelled")
          ).length > 0;
        }
      }

      this.setState({
        isEnableWaitlist: isEnableWaitlist,
        notMember: notMember,
        alreadyEnrolled: alreadyEnrolled,
        cartErrorProperties: cartErrorProperties
      });
    }

    if (this.props.type === "Party") {
      const plan = JSON.parse((() => {
        return this.props.program.tickets[this.props.selected];
      })());

      let numEnrollmentInTicket = 0;

      const param = this.props.match.params.id;
      const customers_ep = `${process.env.REACT_APP_API}/partners/parties/customers/${param}`;
      const customers = await axios.get(customers_ep);

      const purchases = customers.data.data;

      purchases.map(item => {
        if (
          item.status_stage !== "Inactive" &&
          item.plan_id === plan.id
        ) {
          numEnrollmentInTicket++;
        }
      });

      let isEnableWaitlist = !!(
        numEnrollmentInTicket >= plan.ticket_quantity
      );

      let notMember = false;
      let cartErrorProperties = {}
      const allEnrollments = await this.fetchUserEnrollments();

      let alreadyEnrolled = false;
      if (this.props.program.allow_same_enrollment && !this.props.program.application_required && this.global.dsUser) {
        if (!this.props.program.addChild) {
          alreadyEnrolled = allEnrollments.event.filter(user =>
            user.eventId === this.props.program.id &&
            (user.status_stage !== "Inactive" && user.status_stage !== "Cancelled_Unpaid"
              && user.status_stage !== "Rejected" && user.status_stage !== "Cancelled")
          ).length > 0;
        }
      }

      const partyDetails = {}
      if (plan.addOn) {
        partyDetails.addOnAmounts = {}
        for (const addon of plan.addOnItems) {
          partyDetails.addOnAmounts[addon.text] = {
            price: addon.value,
            amount: 0,
            description: addon.description
          }
        }
      }
      partyDetails.startDate = null;
      partyDetails.attendees = []

      this.setState({
        partyDetails: partyDetails,
        isEnableWaitlist: isEnableWaitlist,
        notMember: notMember,
        alreadyEnrolled: alreadyEnrolled,
        cartErrorProperties: cartErrorProperties,
        plan: plan
      });
    }

    if (this.props.type === "Online") {
      const element = JSON.parse(
        this.props.program.plans.filter(
          e => JSON.parse(e).id == this.props.selected
        )[0]
      );

      console.log("PROPS PROGRAM", this.props);

      this.fetchEnrollmentCount(this.props.program.id);

      const isCountLargerThanSpots = !!(
        this.props.program.total_spots &&
        (await this.fetchEnrollmentCount(this.props.program.id)) >=
        this.props.program.total_spots
      );

      let isEnableWaitlist = !!(
        this.props.program.enable_waitlist && isCountLargerThanSpots
      );

      //Secondary check only for semester and free programs at the moment
      if (this.props.planWaitlist === true) {
        isEnableWaitlist = this.props.planWaitlist;
      }

      const isDateEnabled = !!(element.isStartDateEnabled || !element.type);

      const isSingleSessionsTimeEnabled = !!element.isTimeEnabled;
      const isSingleSessionsDateSelectionEnabled =
        !!element.isDateSelectionEnabled;

      const days_of_week = Object.keys(this.props.program.days_of_week);

      const excludedDays = this.getExcludedDays(days_of_week);

      if (isSingleSessionsDateSelectionEnabled || isSingleSessionsTimeEnabled) {
        this.fetchSingleSessionsEnrollments(this.props.program.id);
      }

      let alreadyEnrolled = false;
      if (this.props.program.allow_same_enrollment && this.props.program.program_category !== "Single Sessions" && !this.props.program.application_required && this.global.dsUser) {
        const allEnrollments = await this.fetchUserEnrollments();

        if (!this.props.program.addChild) {
          alreadyEnrolled = allEnrollments.filter(user =>
            user.programId === this.props.program.id &&
            user.status_stage !== "Cancelled" && user.status_stage !== "Inactive" &&
            user.status_stage !== "Cancelled_Unpaid" && user.status_stage !== "Rejected"
          ).length > 0;
        }
      }

      this.setState({
        isProgramSessionsDateSelect: isSingleSessionsDateSelectionEnabled,
        isProgramSessionsTimeSelect: isSingleSessionsTimeEnabled,
        toRemoveDays: excludedDays,
        isRecurringProgramDateSelect: isDateEnabled,
        alreadyEnrolled: alreadyEnrolled
      });
    }

    if (this.props.type === "Membership") {
      let alreadyEnrolled = false;
      if (this.props.program.allow_same_enrollment && !this.props.program.application_required && this.global.dsUser) {
        const allEnrollments = await this.fetchUserEnrollments();

        if (!this.props.program.addChild) {
          alreadyEnrolled = allEnrollments.filter(user =>
            user.membershipId === this.props.program.id &&
            (user.status_stage !== "Inactive" && user.status_stage !== "Cancelled_Unpaid"
              && user.status_stage !== "Rejected" && user.status_stage !== "Cancelled")
          ).length > 0;
        }
      }

      this.setState({
        alreadyEnrolled: alreadyEnrolled
      });
    }

    let isGuardianNull = false;
    const guardKeys = Object.keys(guardians[0]);
    const checkForNull = guardKeys.map((field, i) => {
      if (guardians[0][field] === null) {
        isGuardianNull = true;
        return field;
      }
      return field;
    });

    // Load the dates
    const getTime = date => {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      switch (minutes) {
        case 0:
          return hours;
        case 15:
          return parseFloat(`${hours}.25`);
        case 30:
          return parseFloat(`${hours}.5`);
        case 45:
          return parseFloat(`${hours}.75`);
      }
    };
    const getWeekday = date => {
      switch (date.getDay()) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
      }
    };
    console.log("DATES", this.props.singleSessionSelectedDates);
    if (this.props.singleSessionSelectedDates?.length > 0) {
      if (this.state.isProgramSessionsTimeSelect) {
        const test = this.props.singleSessionSelectedDates.map(e => {
          const date = new Date(e);
          return {
            date: date,
            displayTime: date,
            time: getTime(date),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            weekday: getWeekday(date)
          };
        });

        console.log("TEST, ", test);
        this.setState({
          sessionDateTimes: test
        });
      } else if (this.state.isProgramSessionsDateSelect) {
        this.setState({
          classSessionDailySelectedDays: this.props.singleSessionSelectedDates
        });
      }
    }
    this.setState({ isGuardianNull, guardians, children: childrenArr }, () => {
      this.checkFormContentValidation();
    });
  }

  fetchGiftData = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/gifts/user-list/${this.props.program.partnerId}`;
    const results = await axios.get(ep);
    if (results.data.success) {
      const balance = results.data.data.reduce((a, c) => {
        return a + parseFloat(c.amtRemaining);
      }, 0);
      this.setState({ giftBalance: balance });
    }
  };

  fetchEnrollmentCount = async programId => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/enrollments/count/${programId}`;
    const result = await axios.get(ep);

    this.setState({
      enrollmentCount: parseInt(result.data.data.count)
    });

    return parseInt(result.data.data.count);
  };

  fetchUserEnrollments = async () => {
    let ep;
    if (this.props.type === "Program") {
      ep = `${process.env.REACT_APP_API}/partners/programs/par/customers/all/${this.global.dsUser.id}`;
    } else if (this.props.type === "Online") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/par/customers/all/${this.global.dsUser.id}`;
    } else if (this.props.type === "Membership") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/par/customers/all/${this.global.dsUser.id}`;
    } else if (this.props.type === "Event") {
      const ep1 = `${process.env.REACT_APP_API}/partners/events/par/customers/all/${this.global.dsUser.id}`;
      const ep2 = `${process.env.REACT_APP_API}/partners/memberships/par/customers/all/${this.global.dsUser.id}`
      const result1 = await axios.get(ep1);
      const result2 = await axios.get(ep2);

      return {
        event: result1.data.data,
        membership: result2.data.data
      }
    } else if (this.props.type === "Party") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/par/customers/all/${this.global.dsUser.id}`;
    }
    const result = await axios.get(ep);

    return result.data.data;
  }

  fetchSingleSessionsEnrollments = async programId => {
    let ep;
    if (this.props.type === "Program") {
      ep = `${process.env.REACT_APP_API}/partners/programs/enrollments/session_timing/${programId}`;
    } else {
      ep = `${process.env.REACT_APP_API}/partners/virtual/enrollments/session_timing/${programId}`;
    }
    const result = await axios.get(ep);
    this.setState({
      singleSessionEnrollments: result.data.data.flat().map(e => JSON.parse(e))
    });
  };

  /**
   * Returned the days excluded to the componentDidMount function
   * @param {Array} days_of_week
   */
  getExcludedDays = days_of_week => {
    let excludedDays = [];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    const mappedDays = days.map(e => {
      if (e === days_of_week.filter(f => f === e)[0]) {
        return e;
      } else {
        return excludedDays.push({ name: e, value: e });
      }
    });

    return excludedDays;
  };

  setDay(day, name) {
    let days;
    if (this.state[name]) days = { ...this.state[name] };
    else days = {};
    if (days[day]) delete days[day];
    else days[day] = true;
    if (Object.keys(days).length === 0) {
      const { [name]: day, ...newFormData } = this.state.formData;
      this.setState({ [name]: days, formData: newFormData });
    } else {
      this.setState({
        [name]: days,
        formData: {
          ...this.state.formData,
          [name]: days
        }
      });
    }
  }

  // Don't duplicate form fields that already exist in the profile information
  secondFields(fieldarr, sectionName) {
    let fields;
    if (
      sectionName === "Parent / Guardian" ||
      sectionName === "Profile Information"
    ) {
      fields = fieldarr
        .filter(e => JSON.parse(e).visible)
        .filter(e => !this.state.guardians[0][JSON.parse(e).first_label]);
    } else if (sectionName === "Child Profile") {
      fields = fieldarr.filter(e => JSON.parse(e).visible);

      // if (this.props.program.addChild) {
      //   fields = fields.filter(
      //     e => !this.state.children[0][JSON.parse(e).first_label]
      //   );
      // }
    } else {
      fields = fieldarr.filter(e => JSON.parse(e).visible);
    }

    const arr = [];
    for (let i = 0; i < fields.length; i += 2) {
      if (i + 1 < fields.length) {
        arr.push([fields[i], fields[i + 1]]);
        continue;
      }
      if (i < fields.length) {
        arr.push([fields[i]]);
      }
      if (i >= fields.length) {
        break;
      }
    }
    return arr;
  }
  getFields(fieldarr) {
    const fieldsClone = { ...fieldarr };
    delete fieldsClone.id;
    delete fieldsClone.isArchived;
    const fields = Object.keys(fieldsClone);

    const arr = [];
    for (let i = 0; i < fields.length; i += 2) {
      if (i + 1 < fields.length) {
        arr.push([
          { value: fieldarr[fields[i]], key: fields[i] },
          { value: fieldarr[fields[i + 1]], key: fields[i + 1] }
        ]);
        continue;
      }
      if (i < fields.length) {
        arr.push([{ value: fieldarr[fields[i]], key: fields[i] }]);
      }
      if (i >= fields.length) {
        break;
      }
    }
    console.log("arr", arr);
    return arr;
  }
  componentDidUpdate() {
    if (document.getElementsByClassName("react-datepicker-wrapper")) {
      for (let dates of document.getElementsByClassName(
        "react-datepicker-wrapper"
      )) {
        dates.style.width = "100%";
        dates.style.borderRadius = ".5rem";
        dates.style.transition =
          "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out";
      }
    }
  }

  saveFirstChild = async () => {
    if (
      this.state.newChild.fullName === "" ||
      this.state.newChild.birthDate === "" ||
      this.state.newChild.gender === "" ||
      this.state.newChild.allergies.length === 0 ||
      this.state.newChild.medication.length === 0
    ) {
      toast.error("Please fill in all required fields.");
      return false;
    }
    const endp = `${process.env.REACT_APP_API}/par/profile/child`;
    const result = await axios.post(endp, this.state.newChild);

    if (result.data.success) {
      this.checkFormContentValidation();
      this.setState({
        children: [...this.state.children, this.mapNewChild(result.data.data)],
        selectedChild: result.data.data.id
      });
      if (this.global?.dsUser?.accountType !== "Parent") {
        const ep = `${process.env.REACT_APP_API}/partners/users/update-account-type/parent`;
        const results2 = await axios.put(ep, {
          dsUserId: this.global?.dsUser?.id
        });
        this.setGlobal({
          dsUser: {
            ...this.global.dsUser,
            accountType: "Parent"
          }
        });
      }

      toast.success("Child Updated");
      return true;
    } else {
      toast.error("Error in creating child.");
      return false;
    }
  };

  chooseChild() {
    if (this.state.children.find(e => e.isArchived === false)) {
      return (
        <div style={{ width: "90%", margin: "20px auto" }}>
          <h5
            style={{
              margin: 0,
              color: "black",
              display: "flex"
            }}
          >
            <span className="choose-child">Choose your child</span>
            <span
              className="add-btn"
              onClick={_ => this.setState({ addChildShown: true })}
            >
              + Add
            </span>
          </h5>
          <div className="choose-child-items">
            <label className="choose-label">
              Choose who is attending :
              <span style={{ color: "orange" }}>*</span>
            </label>
            <select
              className={
                this.state.selectedChild === ""
                  ? "choose-select red-border"
                  : "choose-select"
              }
              onChange={e => {
                this.checkFormContentValidation();
                this.setState({ selectedChild: e.target.value });
              }}
            >
              <option value="" selected disabled hidden>
                Select child:
              </option>
              {this.state.children
                .filter(e => e.isArchived === false)
                .map(e => (
                  <option value={e.id}>
                    {e["Full Name"]}
                    {/* + " " + e["Last Name"] */}
                  </option>
                ))}
            </select>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "90%", margin: "20px auto" }}>
          <h5 className="show-summary-title-section">
            <span className="show-summary-title-text">Add Child</span>
          </h5>
          <form style={{ marginTop: "30px" }}>
            <div className="show-summary-form-items">
              <div className="show-form-item">
                <label htmlFor={"Full Name"} className="show-label">
                  Full Name*
                </label>
                <input
                  style={{
                    padding: "5px 10px",
                    border: "1px solid #dcdada"
                  }}
                  className="show-value-input"
                  type="text"
                  value={this.state.newChild.fullName}
                  onChange={e => {
                    const child = this.state.newChild;
                    child.fullName = e.target.value;
                    this.setState({ newChild: child });
                  }}
                />
              </div>
              <div className="show-form-item">
                <label htmlFor={"Birth Date"} className="show-label">
                  Birth Date*
                </label>
                <div
                  style={{
                    paddingRight: "10px",
                    border: "1px solid #dcdada"
                  }}
                  name="Birth Date"
                >
                  <formTypes.DateAddChildModal
                    name="Birth Date"
                    value={this.state.newChild.birthDate}
                    onChange={(_, v) => {
                      const child = this.state.newChild;
                      child.birthDate = v;
                      this.setState({
                        newChild: child
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="show-summary-form-items">
              <div className="show-form-item">
                <label htmlFor={"Gender"} className="show-label">
                  Gender*
                </label>
                <div
                  style={{
                    width: "181px",
                    fontSize: "1.5rem",
                    color: "#626262"
                  }}
                  name="Gender"
                >
                  <formTypes.GenderDropdownSelect
                    name="Gender"
                    value={this.state.newChild.gender}
                    onChange={(_, v) => {
                      const child = this.state.newChild;
                      child.gender = v;
                      this.setState({
                        newChild: child
                      });
                    }}
                  />
                </div>
              </div>
              <div className="show-form-item">
                <label htmlFor={"Allergies"} className="show-label">
                  Allergies*
                </label>
                <div
                  style={{
                    width: "181px",
                    fontSize: "1.5rem",
                    color: "#626262"
                  }}
                  name="Allergies"
                >
                  <formTypes.MultiInput
                    name="Allergies"
                    value={this.state.newChild.allergies}
                    onChange={(_, v) => {
                      const child = this.state.newChild;
                      child.allergies = v;
                      this.setState({
                        newChild: child
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="show-summary-form-items">
              <div className="show-form-item">
                <label htmlFor={"Medication"} className="show-label">
                  Medication*
                </label>
                <div
                  style={{
                    width: "181px",
                    fontSize: "1.5rem",
                    color: "#626262"
                  }}
                  name="Medication"
                >
                  <formTypes.MultiInput
                    name="Medication"
                    value={this.state.newChild.medication}
                    onChange={(_, v) => {
                      const child = this.state.newChild;
                      child.medication = v;
                      this.setState({
                        newChild: child
                      });
                    }}
                  />
                </div>
              </div>
              <div className="show-form-item">
                <label htmlFor={"Health Concerns"} className="show-label">
                  Health Concerns
                </label>
                <div
                  style={{
                    width: "181px",
                    fontSize: "1.5rem",
                    color: "#626262"
                  }}
                  name="Health Concerns"
                >
                  <formTypes.MultiInput
                    name="Health Concerns"
                    value={this.state.newChild.healthConcerns}
                    onChange={(_, v) => {
                      const child = this.state.newChild;
                      child.healthConcerns = v;
                      this.setState({
                        newChild: child
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
  finalQuestions() {
    return (
      <div style={{ width: "90%", margin: "20px auto" }}>
        <h5
          style={{
            margin: 0,
            color: "black",
            display: "flex"
          }}
        >
          <span style={{ flexGrow: 1 }}>{`Choose your desired days`}</span>
        </h5>
        <form style={{ marginTop: "30px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px"
              }}
            ></div>
          </div>
        </form>
      </div>
    );
  }

  reverseMap() {
    const obj = { ...this.mappings };
    Object.keys(this.mappings).forEach(
      e => (obj[e] = this.state["guardians"][0][this.mappings[e]])
    );
    return obj;
  }

  async updateUser() {
    if (this.state.guardians[0]["Cell Phone"].length !== 10) {
      toast.error("Phone number must be 10 digits.");
      return false;
    }
    const endp = `${process.env.REACT_APP_API}/par/profile/parent`;
    const parentObj = {
      ...this.reverseMap(),
      id: this.state.guardians[0].id,
      isFirst: true
    };
    const result = await axios.put(endp, parentObj);
    if (result?.data?.success) {
      this.setState({ duplicatePhone: false });
      toast.success("User Profile Updated!");

      const data = this.global.dsUser;
      this.setGlobal({
        dsUser: {
          ...data,
          displayName: this.state.guardians[0]["Full Name"],
          phone: this.state.guardians[0]["Cell Phone"]
        }
      });
      console.log("result.data.message", result.data.message);
      return true;
    } else {
      if (
        result.data.message ===
        "This phone number is already associated with an account. Try logging in if you already have an account."
      ) {
        toast.error(result.data.message);
        this.setState({
          redHighlight: true,
          duplicatePhone: true,
          openResetPasswordModal: true
        });
      } else {
        toast.error("Error updating user profile");
      }
      return false;
    }
  }

  showSummary(i, cat, info, solo) {
    let isGuardianNull = false;
    const checkForNull = this.getFields(this.state["guardians"][0]).map(row =>
      row.map(field => (!field.value ? (isGuardianNull = true) : field))
    );
    console.log("isGuardianNull", isGuardianNull);
    return (
      <div style={{ width: "90%", margin: "20px auto" }}>
        <h5 className="show-summary-title-section">
          <span className="show-summary-title-text">{`${cat} ${solo ? i + 1 : ""
            }`}</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {info === "guardians" && this.state.isGuardianNull ? null : (
              <span
                onClick={_ =>
                  info === "guardians"
                    ? this.setState({
                      editParentShown: true,
                      editParent: { location: i, id: this.state[info][i].id }
                    })
                    : this.setState({
                      editChildShown: true,
                      editChild: { location: i, id: this.state[info][i].id }
                    })
                }
                className="show-summary-edit-btn"
              >
                Edit
              </span>
            )}
            {info === "children" && (
              <span
                onClick={_ => this.setState({ selectedChild: null })}
                className="show-summary-choose-btn"
              >
                Choose
              </span>
            )}
          </div>
        </h5>
        <form style={{ marginTop: "30px" }}>
          {info === "guardians" && i === 0 && this.state.isGuardianNull ? (
            <>
              {this.getFields(this.state[info][i]).map(row =>
                row.length > 1 ? (
                  <div className="show-summary-form-items">
                    {row.map(field => {
                      return (
                        <div className="show-form-item">
                          <label htmlFor={field.key} className="show-label">
                            {field.key}*
                          </label>
                          <input
                            style={{
                              padding: "5px 10px",
                              border:
                                field.key === "Cell Phone" &&
                                  this.state.redHighlight
                                  ? "1px solid red"
                                  : "1px solid #dcdada"
                            }}
                            maxLength={
                              field.key === "Cell Phone" ? "10" : undefined
                            }
                            className="show-value-input"
                            type={
                              field.key === "Cell Phone" ? "number" : "text"
                            }
                            disabled={
                              field.key === "Full Name" || field.key === "Email"
                            }
                            value={
                              field.value === "undefined" ? "" : field.value
                            }
                            onChange={
                              field.key === "Cell Phone"
                                ? e => {
                                  if (/^[0-9]{0,10}$/.test(e.target.value)) {
                                    const guardVals = this.state[info];
                                    guardVals[i][field.key] = e.target.value;
                                    this.setState({ [info]: guardVals });
                                    this.setState({ redHighlight: false });
                                  }
                                }
                                : e => {
                                  const guardVals = this.state[info];
                                  guardVals[i][field.key] = e.target.value;
                                  this.setState({ [info]: guardVals });
                                  this.setState({ redHighlight: false });
                                }
                            }
                            onKeyDown={event => {
                              if (field.key === "Cell Phone") {
                                const isNumber = /^\d$/.test(event.key); // only allow numbers
                                const isBackspaceOrDelete =
                                  event.key === "Backspace" ||
                                  event.key === "Delete"; // allow backspace and delete
                                if (!isNumber && !isBackspaceOrDelete) {
                                  event.preventDefault();
                                }
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="show-summary-form-items">
                    {row.map(field => {
                      return (
                        <div className="show-form-item">
                          <label htmlFor={field.key} className="show-label">
                            {field.key}*
                          </label>
                          <input
                            style={{
                              padding: "5px 10px",
                              border: "1px solid #dcdada"
                            }}
                            className="show-value-input"
                            type="text"
                            disabled={
                              field.key === "Full Name" || field.key === "Email"
                            }
                            value={field.value}
                            onChange={e => {
                              const guardVals = this.state[info];
                              guardVals[i][field.key] = e.target.value;
                              this.setState({ [info]: guardVals });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </>
          ) : (
            <>
              {this.getFields(this.state[info][i]).map(row =>
                row.length > 1 ? (
                  <div className="show-summary-form-items">
                    {row.map(field => {
                      return (
                        <div className="show-form-item">
                          <label htmlFor={field.key} className="show-label">
                            {field.key}*
                          </label>
                          <p className="show-value">
                            {field.value === "undefined" ? "" : field.value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="show-summary-form-items">
                    {row.map(field => {
                      return (
                        <div className="show-form-item">
                          <label htmlFor={field.key} className="show-label">
                            {field.key}* :
                          </label>
                          <p className="show-value">{field.value}</p>
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </>
          )}
        </form>
      </div>
    );
  }

  isFree = () => {
    console.log("isFree", {
      preGiftTotal: this.global.preGiftTotal,
      giftBalance: this.global.giftBalance,
      giftBalanceState: this.state.giftBalance,
      installments: this.props.installments
    });
    // return true;
    if (this.props.type === "Event") {
      if (this.global.preGiftTotal < this.global.giftBalance) {
        return true;
      }
      const checkPrice = JSON.parse(
        this.props.program.tickets[this.props.selected]
      );

      if (checkPrice.ticket_status === "Free" || checkPrice.ticket_price == 0) {
        return true;
      } else return false;
    }
    if (this.props.type === "Program") {
      const checkPlan = JSON.parse(
        this.props.program.plans.filter(
          e => JSON.parse(e).id == this.props.selected
        )[0]
      );
      console.log("checkPlan:", checkPlan)
      // checkPlan.billing_cycle &&
      if (!this.props.program.auto_withdraw) {
        return true;
      }

      if (
        this.global.preGiftTotal < this.global.giftBalance &&
        !this.props.installments &&
        checkPlan.type
      ) {
        return true;
      }

      if (checkPlan.tuition_rate == 0) {
        return true;
      } else if (checkPlan.total_price == 0) {
        return true;
      } else return false;
    }
    if (this.props.type === "Membership") {
      const checkPrice = JSON.parse(
        this.props.program.prices[this.props.selected]
      );

      if (!checkPrice.auto_withdraw) {
        return true;
      }

      if (
        this.global.preGiftTotal < this.global.giftBalance &&
        !checkPrice.auto_renew
      ) {
        return true;
      }

      if (checkPrice.price_per_term == 0) {
        return true;
      } else return false;
    }
    if (this.props.type === "Online") {
      const checkPlan = JSON.parse(
        this.props.program.plans.filter(
          e => JSON.parse(e).id == this.props.selected
        )[0]
      );

      // checkPlan.billing_cycle &&
      if (!this.props.program.auto_withdraw) {
        return true;
      }

      if (
        this.global.preGiftTotal < this.global.giftBalance &&
        !this.props.installments &&
        checkPlan.type
      ) {
        return true;
      }
      if (checkPlan.type === "Free") {
        return true;
      } else if (checkPlan.total_price == 0) {
        return true;
      } else return false;
    }
  };

  /**
   * This function validates whether all the mandatory fields have been updated in the checkout form
   */
  checkFormValidation() {
    let validationArr = [];
    const sectionData = this.state.sections
      .filter(e => !e.archived)
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map(sec => {
        if (
          sec.form_section_name === "Parent / Guardian" ||
          sec.form_section_name === "Profile Information"
        ) {
          const validationFields = sec.fields
            .filter(e => JSON.parse(e).visible)
            .filter(e => JSON.parse(e).required)
            .filter(e => !this.state.guardians[0][JSON.parse(e).first_label]);

          validationFields.map(f => {
            const element = JSON.parse(f);
            const obj = { id: sec.id, label: element.first_label };
            validationArr.push(obj);
            return f;
          });
        } else if (sec.form_section_name === "Child Profile") {
          let validationFields = sec.fields
            .filter(e => JSON.parse(e).visible)
            .filter(e => JSON.parse(e).required);

          if (this.props.program.addChild) {
            validationFields = validationFields.filter(
              e => !this.state.children[0][JSON.parse(e).first_label]
            );
          }

          validationFields.map(f => {
            const element = JSON.parse(f);
            const obj = { id: sec.id, label: element.first_label };
            validationArr.push(obj);
            return f;
          });
        } else {
          const validationFields = sec.fields
            .filter(e => JSON.parse(e).visible)
            .filter(e => JSON.parse(e).required);

          validationFields.map(f => {
            const element = JSON.parse(f);
            const obj = { id: sec.id, label: element.first_label };
            validationArr.push(obj);
            return f;
          });
        }
        return sec;
      });

    const validationCheck = [];
    validationArr.map(e => {
      const name = `${e.id}${e.label}`;
      if ([name] in this.state.formData) {
        return e;
      } else {
        validationCheck.push({ [name]: true });
        return e;
      }
    });

    this.setState({ requiredFields: validationCheck });
    return validationCheck.length > 0;
  }

  /**
   * This function validates whether the form page is empty or not
   */
  checkFormContentValidation() {
    console.log("STATE in checkFormContentValidation(): ", this.state);
    const validationArr = [];
    const isParentProfileSectionEmpty = false;
    const isChildProfileSectionEmpty = false;

    const sectionData = this.state.sections
      .filter(e => !e.archived)
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map(sec => {
        if (
          sec.form_section_name === "Parent / Guardian" ||
          sec.form_section_name === "Profile Information"
        ) {
          const validationFields = sec.fields
            .filter(e => JSON.parse(e).visible)
            .filter(e => !this.state.guardians[0][JSON.parse(e).first_label]);

          validationFields.map(f => {
            const element = JSON.parse(f);
            const obj = { id: sec.id, label: element.first_label };
            validationArr.push(obj);
            return f;
          });
        } else if (sec.form_section_name === "Child Profile") {
          if (this.state.children.length !== 0) {
            const validationFields = sec.fields
              .filter(e => JSON.parse(e).visible)
              .filter(e => !this.state.children[0][JSON.parse(e).first_label]);

            validationFields.map(f => {
              const element = JSON.parse(f);
              const obj = { id: sec.id, label: element.first_label };
              validationArr.push(obj);
              return f;
            });
          } else {
            const validationFields = sec.fields.filter(
              e => JSON.parse(e).visible
            );

            validationFields.map(f => {
              const element = JSON.parse(f);
              const obj = { id: sec.id, label: element.first_label };
              validationArr.push(obj);
              return f;
            });
          }
        } else {
          const validationFields = sec.fields.filter(
            e => JSON.parse(e).visible
          );

          validationFields.map(f => {
            const element = JSON.parse(f);
            const obj = { id: sec.id, label: element.first_label };
            validationArr.push(obj);
            return f;
          });
        }
        return sec;
      });

    const validationCheck = [];
    let sectionsWithFields = {};
    validationArr.map(e => {
      const name = `${e.id}${e.label}`;
      if ([name] in this.state.formData) {
        return e;
      } else {
        validationCheck.push({ [name]: true, sectionId: e.id });
        return e;
      }
    });

    if (validationCheck.length > 0) {
      validationCheck.map(e => {
        const findSection = this.state.sections.filter(
          f => f.id === e.sectionId
        );
        if (findSection.length > 0) {
          const sectionName = findSection[0].form_section_name;
          sectionsWithFields = { [sectionName]: true };
        }
        return e;
      });
    }

    const isSkipForm = !!this.props?.isSkipForm;

    this.setState({
      checkFormContentValidation: (validationCheck.length > 0 && !isSkipForm) || this.props.type === "Party",
      sectionsWithFields: validationCheck.length > 0 ? sectionsWithFields : {}
    });
    // validationCheck.length > 0
    //   ? this.setState({ checkFormContentValidation: true, sectionsWithFields })
    //   : this.setState({ checkFormContentValidation: false, sectionsWithFields  });
    return;
  }

  handleChange = (e, v) => {
    this.setState({
      [e]: v
    });
  };

  render() {
    // if (this.state.guardians.length === 0 || this.state.children.length === 0) {
    //   return null
    // }
    console.log("this.props", this.props);
    console.log("this.state", this.state);
    console.log("this.global", this.global);

    const { type } = this.props;
    return (
      <div
        style={{
          width: "80%",
          padding: "20px",
          margin: "0 auto",
          paddingBottom: "60px"
        }}
      >
        {this.state.editParentShown && (
          <EditParentModal
            open={this.state.editParentShown}
            params={this.state.editParent}
            update={(e, isFirst) => {
              if (isFirst) {
                const data = this.global.dsUser;
                this.setGlobal({
                  dsUser: {
                    ...data,
                    displayName: e.fullName,
                    phone: e.homePhone
                  }
                });
              }
              this.setState({
                guardians: this.state.guardians.map(v =>
                  v.id === e.id ? this.mapNewParent(e) : v
                )
              });
              this.checkFormContentValidation();
            }}
            onClose={_ =>
              this.setState({ editParentShown: false, editParent: null })
            }
          />
        )}
        {this.state.editChildShown && (
          <EditChildModal
            open={this.state.editChildShown}
            params={this.state.editChild}
            update={e => {
              this.checkFormContentValidation();
              this.setState({
                children: this.state.children.map(v =>
                  v.id === e.id ? this.mapNewChild(e) : v
                )
              });
            }}
            onClose={_ =>
              this.setState({ editChildShown: false, editChild: null })
            }
          />
        )}
        {this.state.addChildShown && (
          <AddChildModal
            open={this.state.addChildShown}
            update={e => {
              this.checkFormContentValidation();
              this.setState({
                children: [...this.state.children, this.mapNewChild(e)]
              });
            }}
            onClose={_ => this.setState({ addChildShown: false })}
          />
        )}
        {this.state.openPromoCodeModal && (
          <AddPromoCodeModal
            program={this.props.program}
            open={this.state.openPromoCodeModal}
            onClose={_ => this.setState({ openPromoCodeModal: false })}
          />
        )}
        {this.state.openResetPasswordModal && (
          <ParentResetPasswordModal
            onClose={_ => this.setState({ openResetPasswordModal: false })}
          />
        )}
        {(this.state.alreadyEnrolled || this.state.notMember) ? (
          <div className="cont mt-5 pt-5" style={{ textAlign: "center" }}>
            <div className="contbox_5">
              <div className="imgdiv">
                <div className="img">
                  {/* <img
                    style={{ maxWidth: 245, maxHeight: 245 }}
                    src={Checkmark}
                    alt=""
                  /> */}
                  {/* <img src={CheckSuc} className="check" alt="" /> */}
                </div>
                <h1 style={{ color: "red" }}>
                  {this.state.notMember ? `You must have the "${this.state.cartErrorProperties.membershipName}" membership to Buy this ticket: ${this.state.cartErrorProperties.name}` :
                    this.props.program.addChild ?
                      <>{this.state.children.filter(e => e.id === parseInt(this.state.selectedChild))[0]["Full Name"]} has already been Enrolled</> : <>You are already Enrolled</>
                  }
                </h1>
                <span
                  style={{ color: "mediumblue", cursor: "pointer" }}
                  onClick={_ => {

                    if (this.props.program.addChild) {
                      this.setState({
                        selectedChild: null,
                        alreadyEnrolled: false
                      })
                    } else {
                      this.props.close()
                    }
                  }}
                >
                  Go Back
                </span>
              </div>
            </div>
          </div>
        ) : (!this.state.success ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid lightgrey",
                marginBottom: "20px",
                padding: "0px"
              }}
            >
              <h4
                data-testid="selection-process"
                className="link-header"
                onClick={_ => this.props.close()}
              >
                Selection Process
              </h4>
              <h4
                className={`link-header ${!this.state.lastPage &&
                  !this.state.form &&
                  !this.state.dropInPage &&
                  !this.state.programAdditionalPage
                  ? "selection"
                  : ""
                  }`}
              // onClick={_ => {
              //   this.setState({
              //     lastPage: false,
              //     form: false,
              //     dropInPage: false,
              //     programAdditionalPage: false
              //   });
              // }}
              >
                Profile
              </h4>
              {this.state.checkFormContentValidation && (
                <h4
                  className={`link-header ${this.state.form ? "selection" : ""
                    }`}
                // onClick={_ => {
                //   this.setState({
                //     lastPage: false,
                //     form: true,
                //     dropInPage: false,
                //     programAdditionalPage: false
                //   });
                // }}
                >
                  Form
                </h4>
              )}
              {(this.props.dropins ||
                this.state.isRecurringProgramDateSelect ||
                this.state.isProgramSessionsDateSelect ||
                this.state.isProgramSessionsTimeSelect) && (
                  <h4
                    className={`link-header ${this.state.dropInPage || this.state.programAdditionalPage
                      ? "selection"
                      : ""
                      }`}
                  // onClick={_ => {
                  //   if (!this.state.isRecurringProgramDateSelect) {
                  //     this.setState({
                  //       lastPage: false,
                  //       form: false,
                  //       dropInPage: true,
                  //       programAdditionalPage: false
                  //     });
                  //   }
                  //   if (this.state.isRecurringProgramDateSelect) {
                  //     this.setState({
                  //       lastPage: false,
                  //       form: false,
                  //       dropInPage: false,
                  //       programAdditionalPage: true
                  //     });
                  //   }
                  // }}
                  >
                    Additional
                  </h4>
                )}
              <h4
                className={`link-header ${this.state.lastPage ? "selection" : ""
                  }`}
              // onClick={_ => {
              //   this.setState({
              //     lastPage: true,
              //     form: false,
              //     dropInPage: false,
              //     programAdditionalPage: false
              //   });
              // }}
              >
                Checkout Summary
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
                padding: "0px 15px"
              }}
            >
              <span
                data-testid="review-application"
                className="application-header"
              >
                Please review your profile information below:
              </span>
              <div className="btn-section">
                <button
                  className="back-btn"
                  // disabled={
                  //   !this.state.lastPage &&
                  //   !this.state.form &&
                  //   !this.state.dropInPage &&
                  //   !this.state.programAdditionalPage
                  // }
                  onClick={_ => {
                    if (
                      !this.state.lastPage &&
                      !this.state.form &&
                      !this.state.dropInPage &&
                      !this.state.programAdditionalPage
                    ) {
                      this.props.close();
                    }

                    if (
                      this.state.lastPage &&
                      this.state.isRecurringProgramDateSelect
                    ) {
                      this.setState({
                        lastPage: false,
                        programAdditionalPage: true
                      });
                      return;
                    }

                    if (
                      this.state.isRecurringProgramDateSelect &&
                      this.state.programAdditionalPage
                    ) {
                      if (this.state.checkFormContentValidation) {
                        this.setState({
                          form: true,
                          programAdditionalPage: false
                        });
                      } else {
                        this.setState({
                          form: false,
                          programAdditionalPage: false
                        });
                      }
                      return;
                    }

                    // Program Single Sessions back from last page
                    if (
                      this.state.lastPage &&
                      (this.state.isProgramSessionsDateSelect ||
                        this.state.isProgramSessionsTimeSelect)
                    ) {
                      this.setState({
                        lastPage: false,
                        programAdditionalPage: true
                      });
                      return;
                    }

                    // Program Single Sessions back to forms/profile page
                    if (
                      (this.state.isProgramSessionsDateSelect ||
                        this.state.isProgramSessionsTimeSelect) &&
                      this.state.programAdditionalPage
                    ) {
                      if (this.state.checkFormContentValidation) {
                        this.setState({
                          form: true,
                          programAdditionalPage: false
                        });
                      } else {
                        this.setState({
                          form: false,
                          programAdditionalPage: false
                        });
                      }
                      return;
                    }

                    if (this.state.lastPage && this.props.dropins) {
                      this.setState({ lastPage: false, dropInPage: true });
                    }
                    if (this.state.lastPage && !this.props.dropins) {
                      if (this.state.checkFormContentValidation) {
                        this.setState({ lastPage: false, form: true });
                      } else {
                        this.setState({ lastPage: false, form: false });
                      }
                    }
                    if (this.state.dropInPage) {
                      if (this.state.checkFormContentValidation) {
                        this.setState({
                          lastPage: false,
                          form: true,
                          dropInPage: false
                        });
                      } else {
                        this.setState({
                          lastPage: false,
                          form: false,
                          dropInPage: false
                        });
                      }
                      return;
                    }
                    if (this.state.form && !this.state.dropInPage) {
                      this.setState({ form: false });
                      return;
                    }
                  }}
                >
                  Back
                </button>
                {!this.state.lastPage && (
                  <button
                    data-testid="next-btn"
                    className="next-btn"
                    onClick={async _ => {
                      let isGuardianNull = false;
                      const checkForNull = this.getFields(
                        this.state["guardians"][0]
                      ).map(row =>
                        row.map(field =>
                          !field.value ? (isGuardianNull = true) : field
                        )
                      );

                      if (
                        this.props.program.addChild &&
                        !this.state.children.find(e => e.isArchived === false)
                      ) {
                        const savedChild = await this.saveFirstChild();
                        if (savedChild === false) {
                          return;
                        }
                      }

                      if (this.props.type === "Party" && this.state.form) {
                        if (!this.state.partyDetails.startDate) {
                          toast.error("Please select a start date")
                          return;
                        }
                        if (this.state.partyDetails.attendees.filter(attendee => !attendee.name || !attendee.email).length > 0) {
                          toast.error("Please fill in all attendee fields")
                          return;
                        }
                        // check if email is valid
                        if (this.state.partyDetails.attendees.filter(attendee =>
                          !attendee.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)).length > 0
                        ) {
                          toast.error("Please ensure valid email format")
                          return;
                        }
                      }

                      if (!this.state.lastPage) {
                        this.saveProfile();
                        if (this.state.dropInPage) {
                          this.setState({
                            lastPage: true,
                            form: false,
                            dropInPage: false
                          });
                        }
                        if (
                          this.state.isRecurringProgramDateSelect &&
                          this.state.programAdditionalPage
                        ) {
                          if (
                            this.state.displayDate === null &&
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                            ).isStartDateEnabled
                          ) {
                            toast.error("Please select a start date");
                            return;
                          }
                          if (
                            this.state.recurringCustomerSelectedDays.length !=
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                            ).days_per_week
                          ) {
                            toast.error("Please select days of the week");
                            return;
                          }

                          this.setState({
                            lastPage: true,
                            programAdditionalPage: false
                          });
                        }
                        // Program Single Sessions after profile next
                        if (
                          (this.state.isProgramSessionsTimeSelect ||
                            this.state.isProgramSessionsDateSelect) &&
                          this.state.programAdditionalPage
                        ) {
                          this.setState({
                            programAdditionalPage: false,
                            form: false,
                            lastPage: true
                          });
                          return;
                        }
                        if (this.state.form) {
                          if (this.checkFormValidation()) {
                            toast.error("Please enter all the required fields");
                            return;
                          }
                          if (this.props.dropins && !this.state.dropInPage) {
                            this.setState({ dropInPage: true, form: false });
                          } else if (
                            this.state.dropInPage &&
                            !this.state.selected_days.length
                          ) {
                            this.setState({ lastPage: false });
                          } else if (
                            this.state.isRecurringProgramDateSelect &&
                            !this.state.programAdditionalPage
                          ) {
                            this.setState({
                              form: false,
                              programAdditionalPage: true
                            });
                          } else if (
                            this.state.isProgramSessionsTimeSelect ||
                            this.state.isProgramSessionsDateSelect
                          ) {
                            this.setState({
                              form: false,
                              programAdditionalPage: true
                            });
                          } else {
                            this.setState({
                              lastPage: true,
                              dropInPage: false,
                              form: false
                            });
                          }
                        }
                        if (
                          !this.state.form &&
                          !this.state.dropInPage &&
                          !this.state.programAdditionalPage
                        ) {
                          if (!this.state.guardians) {
                            return;
                          }
                          if (this.state.guardians.length > 0) {
                            if (
                              !this.state.guardians[0]?.Address ||
                              !this.state.guardians[0]?.Country ||
                              !this.state.guardians[0]?.Email ||
                              !this.state.guardians[0]?.["Full Name"] ||
                              !this.state.guardians[0]?.["Cell Phone"] ||
                              !this.state.guardians[0]?.["Postal / Zip"] ||
                              !this.state.guardians[0]?.Region ||
                              !this.state.guardians[0]?.City
                            ) {
                              toast.error("Please enter the User Profile Info");
                              return;
                            }
                          }

                          if (this.state.isGuardianNull) {
                            if (isGuardianNull) {
                              toast.error("Enter all User Profile Info!");
                              return;
                            }
                            const valid = await this.updateUser();
                            if (!valid) return;
                            await this.checkFormContentValidation();
                          }

                          if (
                            this.props.program.addChild &&
                            !this.state.selectedChild
                          ) {
                            if (
                              this.global.parentGuardians.length > 0 &&
                              !this.global.parentGuardians[0].has_child &&
                              this.state.checkFormContentValidation
                            ) {
                              this.setState({ form: true });
                              return;
                            }
                            if (
                              !this.state.children.find(
                                e => e.isArchived === false
                              )
                            ) {
                              const savedChild = this.saveFirstChild();
                              if (savedChild === true) {
                                this.setState({ form: true });
                                return;
                              } else {
                                return;
                              }
                            } else {
                              toast.error("Please select a child");
                              return;
                            }
                          }

                          if (this.props.type === "Program" || this.props.type === "Online") {
                            if (this.props.program.allow_same_enrollment && this.props.program.program_category !== "Single Sessions" && !this.props.program.application_required && this.props.program.addChild && this.state.selectedChild) {
                              let alreadyEnrolled = false;
                              if (this.global.dsUser) {
                                const allEnrollments = await this.fetchUserEnrollments();
                                alreadyEnrolled = allEnrollments.filter(user =>
                                  user.programId === this.props.program.id &&
                                  user.childId === parseInt(this.state.selectedChild) &&
                                  user.status_stage !== "Inactive" && user.status_stage !== "Cancelled" &&
                                  user.status_stage !== "Cancelled_Unpaid" && user.status_stage !== "Rejected").length > 0;
                                console.log("allEnrollments", allEnrollments)
                                console.log("alreadyEnrolled", alreadyEnrolled)
                              }

                              if (alreadyEnrolled) {
                                this.setState({
                                  alreadyEnrolled: alreadyEnrolled
                                })
                                // toast.error("You have already enrolled this Child")
                                return;
                              }
                            }
                          } else if (this.props.type === "Event") {
                            if (this.props.program.allow_same_enrollment && !this.props.program.application_required &&
                              this.props.program.addChild && this.state.selectedChild) {
                              let alreadyEnrolled = false;
                              if (this.global.dsUser) {
                                const allEnrollments = await this.fetchUserEnrollments();
                                alreadyEnrolled = allEnrollments.event.filter(user =>
                                  user.eventId === this.props.program.id &&
                                  user.childId === parseInt(this.state.selectedChild) &&
                                  (user.status_stage !== "Cancelled" && user.status_stage !== "Inactive" &&
                                    user.status_stage !== "Cancelled_Unpaid")
                                ).length > 0;
                              }

                              if (alreadyEnrolled) {
                                this.setState({
                                  alreadyEnrolled: alreadyEnrolled
                                })
                                // toast.error("You have already enrolled this Child")
                                return;
                              }
                            }
                          }

                          // Recurring Programs after profile next
                          if (this.state.isRecurringProgramDateSelect) {
                            if (this.state.checkFormContentValidation) {
                              this.setState({
                                form: true,
                                programAdditionalPage: false,
                                lastPage: false
                              });
                            } else {
                              this.setState({
                                programAdditionalPage: true,
                                form: false,
                                lastPage: false
                              });
                            }
                          }

                          if (this.props.type === "Party") {
                            this.setState({
                              party_details: true,
                              programAdditionalPage: false,
                              lastPage: false
                            });
                          }

                          // Program Single Sessions after profile next
                          if (
                            this.state.isProgramSessionsTimeSelect ||
                            this.state.isProgramSessionsDateSelect
                          ) {
                            if (this.state.checkFormContentValidation) {
                              this.setState({
                                form: true,
                                programAdditionalPage: false,
                                lastPage: false
                              });
                            } else {
                              this.setState({
                                programAdditionalPage: true,
                                form: false,
                                lastPage: false
                              });
                            }
                            return;
                          }

                          // Semester drop in programs after the profile next
                          if (this.props.dropins) {
                            if (!this.state.dropInPage) {
                              if (this.state.checkFormContentValidation) {
                                this.setState({
                                  form: true,
                                  dropInPage: false
                                });
                              } else {
                                this.setState({
                                  dropInPage: true,
                                  form: false
                                });
                              }
                            }
                          } else if (!this.state.isRecurringProgramDateSelect) {
                            if (this.state.checkFormContentValidation) {
                              this.setState({ form: true });
                            } else {
                              this.setState({ lastPage: true });
                            }
                          }
                          return;
                        }
                      }
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {this.state.openSignModal && (
              <SignModal
                onClose={_ => this.setState({ openSignModal: false })}
                complete={img =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      [this.state.signVar]: img
                    }
                  })
                }
              />
            )}

            {/* {Section for program single sessions time logic for Lessons / Sessions} ProgramSessionsTiming */}
            {this.state.isProgramSessionsTimeSelect &&
              this.state.programAdditionalPage &&
              !this.state.lastPage &&
              !this.state.form &&
              !this.state.dropInPage && (
                <ProgramSessionsTiming
                  singleSessionEnrollments={this.state.singleSessionEnrollments}
                  plan={JSON.parse(
                    this.props.program.plans.filter(
                      e => JSON.parse(e).id == this.props.selected
                    )[0]
                  )}
                  singleSessionQuantity={this.props.singleSessionQuantity}
                  singleSessionSelectedDates={
                    this.props.singleSessionSelectedDates || []
                  }
                  program={this.props.program}
                  hasUnselectedValuesSingleQuantity={e =>
                    this.setState({ singleQuantityTimeUnselectedValidation: e })
                  }
                  hasUnselectedValuesMultipleQuantities={e =>
                    this.setState({
                      multipleQuantityTimeUnselectedValidation: e
                    })
                  }
                  sessionDateTimes={e => this.setState({ sessionDateTimes: e })}
                  sessionDateTimesState={this.state.sessionDateTimes}
                  /* Trying to keep property names in sync with that of CartCheckout.jsx.
                     In future, rename as follows:
                         sessionDateTimeState => sessionDateTime
                         sessionDateTimes     => sessionDateTimesOnChange
                  */
                  startDate={this.state.startDate}
                  disabled={this.props.singleSessionSelectedDates?.length > 0}
                />
              )}

            {/* {Section for program single sessions date logic for classes} ProgramSessionsTiming */}
            {this.state.isProgramSessionsDateSelect &&
              this.state.programAdditionalPage &&
              !this.state.lastPage &&
              !this.state.form &&
              !this.state.dropInPage && (
                <ProgramSessionClassDates
                  plan={getSelectedProgramPlan(
                    this.props.program,
                    this.props.selected
                  )}
                  singleSessionQuantity={this.props.singleSessionQuantity}
                  program={this.props.program}
                  selected={this.props.selected}
                  selected_days={e =>
                    this.setState({ classSessionDailySelectedDays: e })
                  }
                  hasUnselectedDateValues={e =>
                    this.setState({
                      classSessionDailyUnselectedDatesValidation: e
                    })
                  }
                  hasUnselectedDate={e =>
                    this.setState({
                      classSessionWeeklyUnselectedDatesValidation: e
                    })
                  }
                  startDate={e => this.setState({ classSessionWeeklyDate: e })}
                  classSessionDailySelectedDays={
                    this.state.classSessionDailySelectedDays
                  }
                  classSessionWeeklyDate={this.state.classSessionWeeklyDate}
                  sessionWeeklySelectedDays={e =>
                    this.setState({ sessionWeeklySelectedDays: e })
                  }
                  disabled={this.props.singleSessionSelectedDates?.length > 0}
                />
              )}

            {this.state.isRecurringProgramDateSelect &&
              this.state.programAdditionalPage &&
              !this.state.lastPage &&
              !this.state.form &&
              !this.state.dropInPage && (
                <div
                  style={{
                    width: "60%",
                    margin: "0 auto",
                    padding: "20px",
                    backgroundColor: "#fff",
                    borderRadius: "20px",
                    border: "1px solid lightgrey"
                  }}
                >
                  {JSON.parse(
                    this.props.program.plans.filter(
                      e => JSON.parse(e).id == this.props.selected
                    )[0]
                  ).isStartDateEnabled && (
                      <div>
                        <h4 style={{ fontFamily: "Montserrat" }}>
                          Select your start date :{" "}
                        </h4>
                        <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            width: "80%",

                            margin: "0 auto"
                          }}
                        >
                          <div className="row">
                            <div className="col-12 d-flex">
                              <DateSel
                                className="w-100 dob-border"
                                showYearDropdown
                                showMonthDropdown
                                dateFormat="MMMM d, yyyy"
                                name="startDate"
                                selected={this.state.displayDate}
                                disabled={this.state.isStartSet}
                                onChange={e => {
                                  const currentTime = moment().format("h:mm A");
                                  const stringDate =
                                    moment(e).format("YYYY-MM-DD");

                                  // Creates a dateTime combining the currentTime
                                  // and selected Date.
                                  const date = moment(
                                    stringDate + " " + currentTime,
                                    "YYYY-MM-DD h:mm A"
                                  );
                                  // Dates set to ISO so that Date() constructor will be able to understand it.
                                  this.setState({
                                    startDate: date.toISOString(),
                                    displayDate: e,
                                    stripeStartDate: date.toISOString()
                                  });
                                }}
                                minDate={
                                  this.props.program.program_start
                                    ? new Date(this.props.program.program_start) <
                                      new Date()
                                      ? addDays(new Date(), 1)
                                      : new Date(this.props.program.program_start)
                                    : addDays(new Date(), 1)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <h4 style={{ fontFamily: "Montserrat" }}>
                      Days of the Week
                    </h4>
                  </div>

                  <div className="row">
                    <div className="select" style={{ width: "100%" }}>
                      <formTypes.DaysDropdownSelect
                        name="recurringCustomerSelectedDays"
                        value={this.state.recurringCustomerSelectedDays}
                        toremove={this.state.toRemoveDays}
                        onChange={this.handleChange}
                        className="w-100"
                        range={"Monday - Sunday"}
                      // isDisabled={this.state.isStartSet}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          {this.state.recurringCustomerSelectedDays.length >
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                            ).days_per_week ? (
                            <span style={{ color: "red" }}>
                              Selected days cannot exceed number of plan days
                            </span>
                          ) : (
                            <>&nbsp; </>
                          )}
                          {this.state.recurringCustomerSelectedDays.length <
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                            ).days_per_week ? (
                            <span style={{ fontSize: "13px" }}>
                              {JSON.parse(
                                this.props.program.plans.filter(
                                  e => JSON.parse(e).id == this.props.selected
                                )[0]
                              ).days_per_week -
                                this.state.recurringCustomerSelectedDays
                                  .length}{" "}
                              remaining
                            </span>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {this.state.dropInPage && (
              <div
                style={{
                  width: "60%",
                  margin: "0 auto",
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "20px",
                  border: "1px solid lightgrey"
                }}
              >
                <h4 style={{ fontFamily: "Montserrat" }}>
                  Please choose your desired days :{" "}
                </h4>
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                    width: "80%",

                    margin: "0 auto"
                  }}
                >
                  <div className="row">
                    <div className="col-12 d-flex">
                      <DatePickerField
                        handleChange={date => {
                          let wasSelected = false;
                          for (let day of this.state.selected_days) {
                            if (isSameDay(new Date(day), date)) {
                              wasSelected = true;
                              break;
                            }
                          }

                          if (wasSelected) {
                            this.setState({
                              selected_days: this.state.selected_days.filter((day) => !isSameDay(new Date(day), date)).sort((a,b)=> a-b)
                            });
                          } else {
                            this.setState({
                              selected_days: [...this.state.selected_days, date].sort((a,b)=>a-b)
                            });
                          }
                        }}
                        dateFilter={date => {
                          const selectedPlan = JSON.parse(
                            this.props.program.plans.filter(
                              e => JSON.parse(e).id == this.props.selected
                            )[0]);
                          
                          for (let day of selectedPlan.drop_in_days) {
                            if (isSameDay(new Date(day), date)) {
                              return true;
                            }
                          }

                          return false;
                        }}
                        selectedDates={this.state.selected_days}
                        disabledKeyboardNavigation="true"
                        inline="true"
                        wrapperStyles={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexGrow: 1
                        }}
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!this.state.dropInPage &&
              !this.state.lastPage &&
              !this.state.form &&
              !this.state.programAdditionalPage && (
                <div
                  style={{
                    backgroundColor: "rgb(252 252 252 / 1)",
                    border: "1px solid #f3f0eb",
                    padding: "20px",
                    borderRadius: "20px"
                  }}
                >
                  {this.state.guardians
                    .filter(e => e.isArchived === false)
                    .map((sec, i) =>
                      this.showSummary(i, `User Profile`, "guardians", true)
                    )}
                  {this.props.program.addChild &&
                    (this.state.selectedChild
                      ? this.state.children.map(
                        (sec, i) =>
                          sec.id === parseInt(this.state.selectedChild) &&
                          this.showSummary(
                            i,
                            `Child Summary`,
                            "children",
                            false
                          )
                      )
                      : this.state.guardians.length !== 0
                        ? this.chooseChild()
                        : "")}
                </div>
              )}
            {!this.state.lastPage &&
              !this.state.dropInPage &&
              !this.state.programAdditionalPage &&
              this.state.form && (
                <div
                  style={{
                    backgroundColor: "rgb(252 252 252 / 1)",
                    border: "1px solid #f3f0eb",
                    padding: "20px",
                    borderRadius: "20px"
                  }}
                >
                  {/* MARKER */}
                  <form style={{ marginTop: "30px", width: "100%" }}>
                    <>
                      {(this.state.party_details && this.state.plan && this.state.plan.addOn && this.state.plan.addOnItems.length !== 0) && (
                        <div style={{ width: "100%", padding: "0 1em 0 1em" }}>
                          <h3
                            style={{
                              margin: 0,
                              color: "rgb(80, 80, 80)",
                              fontFamily: "Open Sans, sans-serif",
                              paddingLeft: "15px",
                              marginBottom: "10px",
                              marginTop: "15px"
                            }}
                          >
                            Party Add-ons
                          </h3>
                          <div
                            style={{
                              margin: 0,
                              paddingLeft: "15px",
                              marginBottom: "10px",
                              marginTop: "15px"
                            }}
                          >
                            {this.state.plan.addOnItems.map(e => {
                              return (
                                <div
                                  style={{
                                    border: "2px solid gray",
                                    borderRadius: "10px",
                                    padding: '10px',
                                    marginBottom: '10px'
                                  }}
                                >
                                  <label
                                    style={{
                                      color: "grey",
                                      margin: "10px 0px 10px 0px",
                                      fontWeight: "700",
                                      width: "100%",
                                      fontSize: "1em",
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {e.text} +${((e.value * 100) / 100).toFixed(2)} x&nbsp;
                                    <input
                                      type="number"
                                      min="0"
                                      value={this.state.partyDetails.addOnAmounts[e.text].amount}
                                      onChange={(v) => {
                                        const newAddOnAmounts = { ...this.state.partyDetails.addOnAmounts }
                                        newAddOnAmounts[e.text].amount = parseInt(v.target.value)
                                        this.setState({
                                          partyDetails: {
                                            ...this.state.partyDetails,
                                            addOnAmounts: newAddOnAmounts
                                          }
                                        })
                                      }}
                                    >

                                    </input>
                                  </label>
                                  <label
                                    style={{
                                      color: "grey",
                                      margin: "10px 0px 10px 0px",
                                      fontWeight: "700",
                                      width: "100%",
                                      fontSize: "1em",
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    <span style={{ color: '#505050' }}>Description: </span>{e.description}
                                  </label>
                                </div>
                              )
                            }
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.party_details && (
                        <>
                          <div style={{ width: "100%", padding: "0 1em 0 1em" }}>
                            <h3
                              style={{
                                margin: 0,
                                color: "rgb(80, 80, 80)",
                                fontFamily: "Open Sans, sans-serif",
                                paddingLeft: "15px",
                                marginBottom: "10px",
                                marginTop: "15px"
                              }}
                            >
                              Request Date
                            </h3>
                            <div
                              style={{
                                margin: 0,
                                paddingLeft: "15px",
                                marginBottom: "10px",
                                marginTop: "15px"
                              }}
                            >
                              <DateSel
                                className="w-50"
                                showYearDropdown
                                showMonthDropdown
                                showTimeSelect
                                dateFormat="MMMM do yyyy, hh:mm a"
                                name={"Select your date"}
                                onChange={e => {
                                  console.log("DATE", e)
                                  this.setState({
                                    partyDetails: {
                                      ...this.state.partyDetails,
                                      startDate: e
                                    }
                                  })
                                }}
                                style={{
                                }}
                                minDate={new Date()}
                                maxDate={new Date(this.props.program.party_end)}
                                selected={this.state.partyDetails.startDate}
                              />
                            </div>
                          </div>
                          <div style={{ width: "100%", padding: "0 1em 0 1em" }}>
                            <h3
                              style={{
                                margin: 0,
                                color: "rgb(80, 80, 80)",
                                fontFamily: "Open Sans, sans-serif",
                                paddingLeft: "15px",
                                marginBottom: "10px",
                                marginTop: "15px"
                              }}
                            >
                              Attendees
                            </h3>
                            <div
                              style={{
                                margin: 0,
                                paddingLeft: "15px",
                                marginBottom: "10px",
                                marginTop: "15px"
                              }}
                            >
                              {this.state.partyDetails.attendees.map((attendee, index) => (
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "grey",
                                    margin: "10px 0px 10px 0px",
                                    fontWeight: "700",
                                    width: "100%",
                                    fontSize: "1em",
                                    fontFamily: "Open Sans, sans-serif"
                                  }}
                                >
                                  Name:&nbsp;
                                  <input
                                    type="text"
                                    value={this.state.partyDetails.attendees[index].name}
                                    style={{
                                      marginRight: "10px"
                                    }}
                                    onChange={(v) => {
                                      const attendees = this.state.partyDetails.attendees;
                                      attendees[index].name = v.target.value
                                      this.setState({
                                        partyDetails: {
                                          ...this.state.partyDetails,
                                          attendees: attendees
                                        }
                                      })
                                    }}
                                  >
                                  </input>
                                  Email:&nbsp;
                                  <input
                                    type="text"
                                    value={this.state.partyDetails.attendees[index].email}
                                    onChange={(v) => {
                                      const attendees = this.state.partyDetails.attendees;
                                      attendees[index].email = v.target.value
                                      this.setState({
                                        partyDetails: {
                                          ...this.state.partyDetails,
                                          attendees: attendees
                                        }
                                      })
                                    }}
                                  />

                                  <IconButton
                                    style={{
                                      margin: "0px",
                                      borderRadius: "50%",
                                      color: "#ff6663"
                                    }}
                                    onClick={_ => {
                                      const attendees = this.state.partyDetails.attendees;
                                      attendees.splice(index, 1);
                                      this.setState({
                                        partyDetails: {
                                          ...this.state.partyDetails,
                                          attendees: attendees
                                        }
                                      })
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </label>
                              ))}

                              <span
                                style={{
                                  backgroundColor: "#5cc8dc",
                                  color: WHITE,
                                  marginRight: 40,
                                  cursor: "pointer"
                                }}
                                className="add-btn"
                                onClick={e => {
                                  this.setState({
                                    partyDetails: {
                                      ...this.state.partyDetails,
                                      attendees: [...this.state.partyDetails.attendees, { name: "", email: "" }]
                                    }
                                  })
                                }}
                              // type={BUTTON}
                              >
                                {"+ Add"}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </>

                    {this.state.sections
                      .filter(e => !e.archived)
                      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
                      .map(sec => (
                        <div style={{ width: "100%", padding: "0 1em 0 1em" }}>
                          <h3
                            style={{
                              margin: 0,
                              color: "rgb(80, 80, 80)",
                              fontFamily: "Open Sans, sans-serif",
                              paddingLeft: "15px",
                              marginBottom: "10px",
                              marginTop: "15px"
                            }}
                          >
                            {this.secondFields(
                              sec.fields,
                              sec.form_section_name
                            ).length !== 0
                              ? sec.form_section_name
                              : ""}
                          </h3>

                          {this.secondFields(
                            sec.fields,
                            sec.form_section_name
                          ).map(row =>
                            row.length > 1 ? (
                              <div
                                className="line-container"
                                style={{ flexWrap: "wrap" }}
                              >
                                {row.map((f, rowNum) => {
                                  const field =
                                    typeof f === "string" ? JSON.parse(f) : f;
                                  const showRequired =
                                    this.state.requiredFields &&
                                    this.state.requiredFields.filter(
                                      reqField => {
                                        const keysArray = Object.keys(reqField);
                                        console.log(keysArray);

                                        const key = keysArray[0];
                                        return (
                                          key === sec.id + field.first_label
                                        );
                                      }
                                    ).length > 0;

                                  return (
                                    <div
                                      className="form-container"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        style={{
                                          color: "grey",
                                          margin: "10px 0px 10px 0px",
                                          fontWeight: "700",
                                          width: "100%",
                                          fontSize: "1em",
                                          fontFamily: "Open Sans, sans-serif"
                                        }}
                                        htmlFor={field.first_label}
                                      >
                                        {field.first_label}{" "}
                                        {field.required && (
                                          <span
                                            style={{
                                              color: showRequired
                                                ? "red"
                                                : undefined
                                            }}
                                          >
                                            *{" "}
                                          </span>
                                        )}
                                        {field.required && showRequired && (
                                          <span
                                            style={{
                                              fontStyle: "italic",
                                              color: "red"
                                            }}
                                          >
                                            Required
                                          </span>
                                        )}
                                      </label>
                                      {field.type === "file"
                                        ? this.state.formData[
                                          sec.id + field.first_label
                                        ]
                                          ? "File Uploaded"
                                          : this.elements(
                                            field.field,
                                            field.first_label,
                                            field.type,
                                            "100%",
                                            sec.id,
                                            field.required,
                                            [],
                                            false,
                                            field.policyOptions,
                                            sec,
                                            field,
                                            rowNum
                                          )
                                        : this.elements(
                                          field.field,
                                          field.first_label,
                                          field.type,
                                          "100%",
                                          sec.id,
                                          field.required,
                                          field.options,
                                          false,
                                          field.policyOptions,
                                          sec,
                                          field,
                                          rowNum
                                        )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                {row.map(f => {
                                  const field = JSON.parse(f);
                                  const showRequired =
                                    this.state.requiredFields &&
                                    this.state.requiredFields.filter(
                                      reqField => {
                                        const keysArray = Object.keys(reqField);
                                        console.log(keysArray);

                                        const key = keysArray[0];
                                        return (
                                          key === sec.id + field.first_label
                                        );
                                      }
                                    ).length > 0;
                                  return (
                                    <div
                                      style={{
                                        width: "100%",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        padding: "0 15px 0 15px"
                                      }}
                                    >
                                      <label
                                        htmlFor={field.first_label}
                                        style={{
                                          color: "grey",
                                          margin: "10px 0px 10px 0px",
                                          fontWeight: "700",
                                          width: "100%",
                                          fontSize: "1em",
                                          fontFamily: "Open Sans, sans-serif"
                                        }}
                                      >
                                        {field.first_label}{" "}
                                        {field.required && (
                                          <span
                                            style={{
                                              color: showRequired
                                                ? "red"
                                                : undefined
                                            }}
                                          >
                                            *{" "}
                                          </span>
                                        )}
                                        {field.required && showRequired && (
                                          <span
                                            style={{
                                              fontStyle: "italic",
                                              color: "red"
                                            }}
                                          >
                                            Required
                                          </span>
                                        )}
                                      </label>
                                      {this.elements(
                                        field.field,
                                        field.first_label,
                                        field.type,
                                        "100%",
                                        sec.id,
                                        field.required,
                                        field.options,
                                        false,
                                        field.policyOptions,
                                        sec,
                                        field
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                  </form>
                </div>
              )}
            {this.state.lastPage && (
              <div className="payment-card">
                <div>
                  <ul
                    style={{
                      padding: "0px",
                      borderBottom: "1px solid #dedede"
                    }}
                  >
                    {type === PROP_PROGRAM && (
                      <CheckoutProgram
                        program={this.props.program}
                        selected={this.props.selected}
                        promotion={this.props.promotion}
                        coupon={this.global.coupon}
                        type={this.props.type}
                        installments={this.props.installments}
                        dropins={this.props.dropins}
                        displayDate={this.state.displayDate}
                        selected_days={this.state.selected_days}
                        giftBalance={this.global.giftBalance}
                        isWaiveDeposit={this.state.isWaiveDeposit}
                        singleSessionQuantity={this.props.singleSessionQuantity}
                        depositAmount={e => {
                          this.setState({
                            depositAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        itemAmount={e => {
                          this.setState({
                            itemAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        promotionAmount={e => {
                          this.setState({
                            promotionAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        couponAmount={e => {
                          this.setState({
                            couponAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        couponValue={this.state.couponValue}
                        taxAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            taxAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                        taxes={this.state.taxAmount}
                        creditCardFeeAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);

                          this.setState({
                            creditCardFeeAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                        totalAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            totalAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                        addOnSemesterItemAmount={e => {
                          this.setState({
                            addOnSemesterItemAmount:
                              parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        selectedChild={this.state.selectedChild}
                      />
                    )}
                    {type === PROP_EVENT && (
                      <CheckoutEvent
                        program={this.props.program}
                        selected={this.props.selected}
                        promotion={this.props.promotion}
                        coupon={this.global.coupon}
                        type={this.props.type}
                        giftBalance={this.global.giftBalance}
                        itemAmount={e => {
                          this.setState({
                            itemAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        promotionAmount={e => {
                          this.setState({
                            promotionAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        couponAmount={e => {
                          this.setState({
                            couponAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        taxAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            taxAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                        totalAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            totalAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                      />
                    )}
                    {type === PROP_PARTY && (
                      <CheckoutParty
                        program={{ ...this.props.program, partyDetails: this.state.partyDetails }}
                        selected={this.props.selected}
                        promotion={this.props.promotion}
                        coupon={this.global.coupon}
                        type={this.props.type}
                        giftBalance={this.global.giftBalance}
                        itemAmount={e => {
                          this.setState({
                            itemAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        promotionAmount={e => {
                          this.setState({
                            promotionAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        couponAmount={e => {
                          this.setState({
                            couponAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        taxAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            taxAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                        totalAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            totalAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                      />
                    )}
                    {type === PROP_MEMBERSHIP && (
                      <CheckoutMembership
                        program={this.props.program}
                        selected={this.props.selected}
                        promotion={this.props.promotion}
                        coupon={this.global.coupon}
                        type={this.props.type}
                        giftBalance={this.global.giftBalance}
                        itemAmount={e => {
                          this.setState({
                            itemAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        promotionAmount={e => {
                          this.setState({
                            promotionAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        couponAmount={e => {
                          this.setState({
                            couponAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        taxAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            taxAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                        totalAmount={e => {
                          const amountFormat = RoundToTwo(e, 2);
                          this.setState({
                            totalAmount: amountFormat * DOLLAR_TO_CENTS
                          });
                        }}
                      />
                    )}
                    {type === PROP_ONLINE && (
                      <CheckoutOnline
                        program={this.props.program}
                        selected={this.props.selected}
                        promotion={this.props.promotion}
                        coupon={this.global.coupon}
                        type={this.props.type}
                        installments={this.props.installments}
                        dropins={this.props.dropins}
                        displayDate={this.state.displayDate}
                        selected_days={this.state.selected_days}
                        giftBalance={this.global.giftBalance}
                        isWaiveDeposit={this.state.isWaiveDeposit}
                        couponValue={this.state.couponAmount}
                        singleSessionQuantity={this.props.singleSessionQuantity}
                        depositAmount={e => {
                          this.setState({
                            depositAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        itemAmount={e => {
                          this.setState({
                            itemAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        promotionAmount={e => {
                          this.setState({
                            promotionAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        couponAmount={e => {
                          this.setState({
                            couponAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        taxAmount={e => {
                          this.setState({
                            taxAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        taxes={(() => {
                          console.log(
                            "passing into checkout online this.state.taxAmount",
                            this.state.taxAmount
                          );
                          return this.state.taxAmount;
                        })()}
                        creditCardFeeAmount={e => {
                          this.setState({
                            creditCardFeeAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        totalAmount={e => {
                          this.setState({
                            totalAmount: parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                        addOnSemesterItemAmount={e => {
                          this.setState({
                            addOnSemesterItemAmount:
                              parseFloat(e) * DOLLAR_TO_CENTS
                          });
                        }}
                      />
                    )}
                  </ul>
                </div>
                <div style={{ padding: "10px", position: "relative" }}>
                  {!this.props.promotion && (
                    <>
                      <button
                        onClick={_ =>
                          this.setState({ openPromoCodeModal: true })
                        }
                        style={{
                          color: "#4EB1F4",
                          fontWeight: 600,
                          cursor: "pointer",
                          backgroundColor: "Transparent",
                          border: "none"
                        }}
                      >
                        Do you have a Promo Code?
                      </button>
                    </>
                  )}
                  <br />
                  {!this.props.program.application_required &&
                    !this.props.promotion &&
                    this.state.giftBalance > 0 && (
                      <>
                        <button
                          onClick={_ =>
                            this.setGlobal({
                              giftBalance: this.state.giftBalance
                            })
                          }
                          style={{
                            color: "#ff6663",
                            fontWeight: 600,
                            cursor: "pointer",
                            backgroundColor: "Transparent",
                            border: "none"
                          }}
                        >
                          {Math.abs(
                            this.global.giftBalance - this.state.giftBalance
                          ) < 0.001
                            ? `Remaining Gift Card Balance: $${(
                              this.global.giftBalance -
                              applyGift(
                                this.props.program.manual_invoices,
                                this.global.giftBalance,
                                this.global.preGiftTotal
                              ).giftDiscount
                            ).toFixed(2)}. Confirm order to apply!`
                            : `Gift Card Balance: $${this.state.giftBalance.toFixed(
                              2
                            )}. Click to
                        apply!`}
                        </button>
                        <br />
                        {/* Show disclaimer text only after the gift balance has been applied. */}
                        {this.global.giftBalance > 0 && (
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "2rem"
                            }}
                          >
                            Note: For recurring/installment payments, any gift
                            card balance will be applied only to the first full
                            payment.
                          </p>
                        )}
                      </>
                    )}
                  <h4
                    style={{
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 600
                    }}
                  >
                    Payment Details
                  </h4>

                  {/* Confirmed by Moh: Old Payment only for program, online, or membership recurring autopay */}
                  {((this.props.type === "Program" ||
                    this.props.type === "Online") &&
                    this.props.program.auto_withdraw &&
                    !this.props.program.application_required &&
                    !this.isFree() &&
                    !this.state.isEnableWaitlist) ||
                    (this.props.type === "Membership" &&
                      JSON.parse(this.props.program.prices[this.props.selected])
                        .auto_withdraw) ? (
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
                      <Elements>
                        <CardForm
                          update={e =>
                            this.setState({
                              paymentInfo: e
                            })
                          }
                          paymentInfo={this.state.paymentInfo}
                          program={this.props.program}
                          plan={
                            type === "Program" || type === "Online"
                              ? this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                              : type === "Event"
                                ? this.props.program.tickets[this.props.selected]
                                : this.props.program.prices[this.props.selected]
                          }
                          partner={this.props.program.partnerId}
                          type={type}
                          success={_ => this.setState({ success: true })}
                          installments={this.props.installments}
                          dropins={this.props.dropins}
                          child={this.state.selectedChild}
                          selectedDays={this.state.selected_days}
                          formData={this.state.formData}
                          startDate={this.state.startDate}
                          stripeStartDate={this.state.stripeStartDate}
                          formId={this.props.form}
                          promotion={this.props.promotion}
                          recurringCustomerSelectedDays={
                            this.state.recurringCustomerSelectedDays
                          }
                          itemAmount={this.state.itemAmount}
                          depositAmount={this.state.depositAmount}
                          taxAmount={this.state.taxAmount}
                          promotionAmount={this.state.promotionAmount}
                          couponAmount={this.state.couponAmount}
                          creditCardFeeAmount={this.state.creditCardFeeAmount}
                          totalAmount={this.state.totalAmount}
                          addOnSemesterItemAmount={
                            this.state.addOnSemesterItemAmount
                          }
                          isWaiveDeposit={this.state.isWaiveDeposit}
                        />
                      </Elements>
                    </StripeProvider>
                  ) : (
                    <>
                      {!this.props.program.manual_invoices || this.props.program.application_required ? (
                        <button
                          type="button"
                          onClick={_ => {
                            //prevent double submit
                            if (this.state.isSubmitted) return;
                            this.setState({ isSubmitted: true });
                            this.manualPayment();
                          }}
                          style={{
                            padding: "10px",
                            display: "block",
                            marginLeft: "auto",
                            marginBottom: 0
                          }}
                          disabled={this.state.isSubmitted}
                        >
                          {this.props.program.application_required ||
                            this.state.isEnableWaitlist
                            ? "Submit Application"
                            : "Confirm Order"}
                          {this.state.isSubmitted && (
                            <i
                              style={{ marginLeft: "10px" }}
                              className="fas fa-spinner fa-spin"
                            />
                          )}
                        </button>
                      ) : (
                        <>
                          <p
                            style={{
                              padding: "20px",
                              fontFamily: "Open Sans, sans-serif"
                            }}
                          >
                            <b>{this.props.organization}</b> has allowed for offline payments for this {type === "Online" ? "program" : type.toLowerCase()}.
                            After confirming your order, you will be fully enrolled but 
                            you will have 
                            {
                              this.state.depositAmount && this.state.depositAmount > 0
                              ? " 2 pending invoices until your payments are received (one for the deposit and another for your first payment)."
                              : this.props.installments
                                ? " a pending invoice for your first installment until your payment is received."
                                : " a pending invoice until your payment is received."
                            }
                          </p>
                          <button
                            type="button"
                            onClick={_ => {
                              //prevent double submit
                              if (this.state.isSubmitted) return;
                              this.setState({ isSubmitted: true });
                              this.manualPayment();
                            }}
                            style={{
                              padding: "10px",
                              display: "block",
                              marginLeft: "auto",
                              marginBottom: 0
                            }}
                            disabled={this.state.isSubmitted}
                          >
                            Confirm Order
                            {this.state.isSubmitted && (
                              <i
                                style={{ marginLeft: "10px" }}
                                className="fas fa-spinner fa-spin"
                              />
                            )}
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
                marginTop: "20px",
                padding: "0px 15px",
                justifyContent: "center"
              }}
            >
              <div className="btn-section">
                {!this.state.lastPage && (
                  <button
                    className="back-btn"
                    // disabled={
                    //   !this.state.lastPage &&
                    //   !this.state.form &&
                    //   !this.state.dropInPage &&
                    //   !this.state.programAdditionalPage
                    // }
                    onClick={_ => {
                      if (
                        !this.state.lastPage &&
                        !this.state.form &&
                        !this.state.dropInPage &&
                        !this.state.programAdditionalPage
                      ) {
                        this.props.close();
                      }

                      if (
                        this.state.lastPage &&
                        this.state.isRecurringProgramDateSelect
                      ) {
                        this.setState({
                          lastPage: false,
                          programAdditionalPage: true
                        });
                        return;
                      }

                      if (
                        this.state.isRecurringProgramDateSelect &&
                        this.state.programAdditionalPage
                      ) {
                        if (this.state.checkFormContentValidation) {
                          this.setState({
                            form: true,
                            programAdditionalPage: false
                          });
                        } else {
                          this.setState({
                            form: false,
                            programAdditionalPage: false
                          });
                        }
                        return;
                      }

                      // Program Single Sessions back from last page
                      if (
                        this.state.lastPage &&
                        (this.state.isProgramSessionsDateSelect ||
                          this.state.isProgramSessionsTimeSelect)
                      ) {
                        this.setState({
                          lastPage: false,
                          programAdditionalPage: true
                        });
                        return;
                      }

                      // Program Single Sessions back to forms/profile page
                      if (
                        (this.state.isProgramSessionsDateSelect ||
                          this.state.isProgramSessionsTimeSelect) &&
                        this.state.programAdditionalPage
                      ) {
                        if (this.state.checkFormContentValidation) {
                          this.setState({
                            form: true,
                            programAdditionalPage: false
                          });
                        } else {
                          this.setState({
                            form: false,
                            programAdditionalPage: false
                          });
                        }
                        return;
                      }

                      if (this.state.lastPage && this.props.dropins) {
                        this.setState({ lastPage: false, dropInPage: true });
                      }
                      if (this.state.lastPage && !this.props.dropins) {
                        if (this.state.checkFormContentValidation) {
                          this.setState({ lastPage: false, form: true });
                        } else {
                          this.setState({ lastPage: false, form: false });
                        }
                      }
                      if (this.state.dropInPage) {
                        if (this.state.checkFormContentValidation) {
                          this.setState({
                            lastPage: false,
                            form: true,
                            dropInPage: false
                          });
                        } else {
                          this.setState({
                            lastPage: false,
                            form: false,
                            dropInPage: false
                          });
                        }
                        return;
                      }
                      if (this.state.form && !this.state.dropInPage) {
                        this.setState({ form: false });
                        return;
                      }
                    }}
                  >
                    Back
                  </button>
                )}
                {!this.state.lastPage && (
                  <button
                    className="next-btn"
                    onClick={async _ => {
                      let isGuardianNull = false;
                      const checkForNull = this.getFields(
                        this.state["guardians"][0]
                      ).map(row =>
                        row.map(field =>
                          !field.value ? (isGuardianNull = true) : field
                        )
                      );

                      if (
                        this.props.program.addChild &&
                        !this.state.children.find(e => e.isArchived === false)
                      ) {
                        const savedChild = await this.saveFirstChild();
                        if (savedChild === false) {
                          return;
                        }
                      }

                      if (this.props.type === "Party" && this.state.form) {
                        if (!this.state.partyDetails.startDate) {
                          toast.error("Please select a start date")
                          return;
                        }
                        if (this.state.partyDetails.attendees.filter(attendee => !attendee.name || !attendee.email).length > 0) {
                          toast.error("Please fill in all attendee fields")
                          return;
                        }
                        // check if email is valid
                        if (this.state.partyDetails.attendees.filter(attendee =>
                          !attendee.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)).length > 0
                        ) {
                          toast.error("Please ensure valid email format")
                          return;
                        }
                      }

                      if (!this.state.lastPage) {
                        this.saveProfile();
                        if (this.state.dropInPage && this.state.selected_days.length > 0) {
                          this.setState({
                            lastPage: true,
                            form: false,
                            dropInPage: false
                          });
                        } else if (this.state.dropInPage) {
                          toast.error("Please select at least one date for drop-ins!");
                        }
                        if (
                          this.state.isRecurringProgramDateSelect &&
                          this.state.programAdditionalPage
                        ) {
                          if (
                            this.state.displayDate === null &&
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                            ).isStartDateEnabled
                          ) {
                            toast.error("Please select a start date");
                            return;
                          }
                          if (
                            this.state.recurringCustomerSelectedDays.length !=
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.props.selected
                              )[0]
                            ).days_per_week
                          ) {
                            toast.error("Please select days of the week");
                            return;
                          }

                          this.setState({
                            lastPage: true,
                            programAdditionalPage: false
                          });
                        }
                        // Program Single Sessions after profile next
                        if (
                          (this.state.isProgramSessionsTimeSelect ||
                            this.state.isProgramSessionsDateSelect) &&
                          this.state.programAdditionalPage
                        ) {
                          this.setState({
                            programAdditionalPage: false,
                            form: false,
                            lastPage: true
                          });
                          return;
                        }
                        if (this.state.form) {
                          if (this.checkFormValidation()) {
                            toast.error("Please enter all the required fields");
                            return;
                          }      
                          if (this.props.dropins && !this.state.dropInPage) {
                            this.setState({ dropInPage: true, form: false });
                          } else if (
                            this.state.dropInPage &&
                            this.state.selected_days.length === 0
                          ) {
                            this.setState({ lastPage: false });
                          } else if (
                            this.state.isRecurringProgramDateSelect &&
                            !this.state.programAdditionalPage
                          ) {
                            this.setState({
                              form: false,
                              programAdditionalPage: true
                            });
                          } else if (
                            this.state.isProgramSessionsTimeSelect ||
                            this.state.isProgramSessionsDateSelect
                          ) {
                            this.setState({
                              form: false,
                              programAdditionalPage: true
                            });
                          } else {
                            this.setState({
                              lastPage: true,
                              dropInPage: false,
                              form: false
                            });
                          }
                        }
                        if (
                          !this.state.form &&
                          !this.state.dropInPage &&
                          !this.state.programAdditionalPage
                        ) {
                          if (!this.state.guardians) {
                            return;
                          }
                          if (this.state.guardians.length > 0) {
                            if (
                              !this.state.guardians[0]?.Address ||
                              !this.state.guardians[0]?.Country ||
                              !this.state.guardians[0]?.Email ||
                              !this.state.guardians[0]?.["Full Name"] ||
                              !this.state.guardians[0]?.["Cell Phone"] ||
                              !this.state.guardians[0]?.["Postal / Zip"] ||
                              !this.state.guardians[0]?.Region ||
                              !this.state.guardians[0]?.City
                            ) {
                              toast.error("Please enter the User Profile Info");
                              return;
                            }
                          }

                          if (this.state.isGuardianNull) {
                            if (isGuardianNull) {
                              toast.error("Enter all User Profile Info!");
                              return;
                            }
                            console.log("updateUser line 3522");
                            const valid = await this.updateUser();
                            if (!valid) return;
                            await this.checkFormContentValidation();
                          }

                          if (
                            this.props.program.addChild &&
                            !this.state.selectedChild
                          ) {
                            if (
                              this.global.parentGuardians.length > 0 &&
                              !this.global.parentGuardians[0].has_child &&
                              this.state.checkFormContentValidation
                            ) {
                              this.setState({ form: true });
                              return;
                            }
                            toast.error("Please select a child");
                            return;
                          }

                          if (this.props.type === "Program" || this.props.type === "Online") {
                            if (this.props.program.allow_same_enrollment && this.props.program.program_category !== "Single Sessions" && !this.props.program.application_required && this.props.program.addChild && this.state.selectedChild) {
                              let alreadyEnrolled = false;
                              if (this.global.dsUser) {
                                const allEnrollments = await this.fetchUserEnrollments();
                                console.log(allEnrollments)
                                alreadyEnrolled = allEnrollments.filter(user =>
                                  user.programId === this.props.program.id &&
                                  user.childId === parseInt(this.state.selectedChild) &&
                                  user.status_stage !== "Cancelled" && user.status_stage !== "Inactive" &&
                                  user.status_stage !== "Cancelled_Unpaid" && user.status_stage !== "Rejected").length > 0;
                                console.log(alreadyEnrolled)
                              }

                              if (alreadyEnrolled) {
                                this.setState({
                                  alreadyEnrolled: alreadyEnrolled
                                })
                                // toast.error("You have already enrolled this Child")
                                return;
                              }
                            }
                          } else if (this.props.type === "Event") {
                            if (this.props.program.allow_same_enrollment && !this.props.program.application_required &&
                              this.props.program.addChild && this.state.selectedChild) {
                              let alreadyEnrolled = false;
                              if (this.global.dsUser) {
                                const allEnrollments = await this.fetchUserEnrollments();
                                alreadyEnrolled = allEnrollments.event.filter(user =>
                                  user.eventId === this.props.program.id &&
                                  user.childId === parseInt(this.state.selectedChild) &&
                                  (user.status_stage !== "Inactive" && user.status_stage !== "Cancelled_Unpaid"
                                    && user.status_stage !== "Rejected" && user.status_stage !== "Cancelled")
                                ).length > 0;
                              }

                              if (alreadyEnrolled) {
                                this.setState({
                                  alreadyEnrolled: alreadyEnrolled
                                })
                                // toast.error("You have already enrolled this Child")
                                return;
                              }
                            }
                          }

                          // Recurring Programs after profile nextx
                          if (this.state.isRecurringProgramDateSelect) {
                            if (this.state.checkFormContentValidation) {
                              this.setState({
                                form: true,
                                programAdditionalPage: false,
                                lastPage: false
                              });
                            } else {
                              this.setState({
                                programAdditionalPage: true,
                                form: false,
                                lastPage: false
                              });
                            }
                          }

                          // Program Single Sessions after profile next
                          if (
                            this.state.isProgramSessionsTimeSelect ||
                            this.state.isProgramSessionsDateSelect
                          ) {
                            if (this.state.checkFormContentValidation) {
                              this.setState({
                                form: true,
                                programAdditionalPage: false,
                                lastPage: false
                              });
                            } else {
                              this.setState({
                                programAdditionalPage: true,
                                form: false,
                                lastPage: false
                              });
                            }
                            return;
                          }

                          if (this.props.type === "Party") {
                            this.setState({
                              party_details: true,
                              programAdditionalPage: false,
                              lastPage: false
                            });
                          }

                          // Semester drop in programs after the profile next
                          if (this.props.dropins) {
                            if (!this.state.dropInPage) {
                              if (this.state.checkFormContentValidation) {
                                this.setState({
                                  form: true,
                                  dropInPage: false
                                });
                              } else {
                                this.setState({
                                  dropInPage: true,
                                  form: false
                                });
                              }
                            }
                          } else if (!this.state.isRecurringProgramDateSelect) {
                            if (this.state.checkFormContentValidation) {
                              this.setState({ form: true });
                            } else {
                              this.setState({ lastPage: true });
                            }
                          }
                          return;
                        }
                      }
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </>
        ) : !this.props.program.manual_invoices || this.props.program.application_required
        ? (
          <CheckoutSubmission
            history={this.props.history}
            program={this.props.program}
            type={type}
          />
        )
        : (
          <OfflinePaymentCheckoutSubmission
            history={this.props.history}
            type={this.props.type}
            program={this.props.program}
            receiptId={this.state.orderReceiptId}
          />
        )
      )}

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Checkout));
