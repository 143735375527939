import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useEffect, useState } from "reactn";
// import PropTypes from 'prop-types'
import { CUnisex, CheckSuc, Checkmark } from "../../../assets";
import "../../../assets/css/componentSpecificCss/OfflinePaymentSubmission.css";

const OfflinePaymentCheckoutSubmission = props => {
  const invoiceURL = `/user/custom-receipt/${props.receiptId}`;
  const payOnlineUrl = `/user/invoice/${props.receiptId}/false`;
  console.log("landing page props:", props);
  return (
    <div className="cont mt-5 pt-5" style={{ textAlign: "center" }}>
      <div className="contbox_5">
        <div className="imgdiv">
          <div className="img">
            <img
              style={{ maxWidth: 245, maxHeight: 245 }}
              src={Checkmark}
              alt=""
            />
            {/* <img src={CheckSuc} className="check" alt="" /> */}
          </div>
          <h1 id="heading-text" style={{ color: "green", textAlign: "center" }}>
            Congratulations, your enrollment is on its way!
          </h1>
          <h5>
            Please remember to pay {props.program.organization_title} by {props.type === "Online" ? "email transfer" : "either cash or email transfer"}. 
          </h5>
          <span
            style={{ color: "mediumblue", cursor: "pointer" }}
            onClick={_ => {
              // props.history.push(
              //   `/programs/${props.match.params.slug}/${type}/${props.match.params.id}`
              // );
              window.open(invoiceURL, "_blank");
            }}
          > 
            View Invoice 
          </span>
          <h5>
            Alternatively, you can also pay through credit card using this link below:
          </h5>
          <span
            style={{ color: "mediumblue", cursor: "pointer" }}
            onClick={_ => {
              // props.history.push(
              //   `/programs/${props.match.params.slug}/${type}/${props.match.params.id}`
              // );
              window.open(payOnlineUrl, "_blank");
            }}
          >
            Pay Invoice Online
          </span>
        </div>
      </div>
    </div>
  );
};

OfflinePaymentCheckoutSubmission.propTypes = {};

export default withRouter(OfflinePaymentCheckoutSubmission);
